import sharedColumns from "./sharedColumns";
import INPUT_OPTIONS from "@/config/inputOptions/HumanResourceManagement";
import VALIDATOR from "@/config/validator";

const accountId = sharedColumns.accountId;
const actionCRUD = sharedColumns.actionCRUD;
const actionExtra = sharedColumns.actionExtra;
const departmentId = sharedColumns.departmentId;
const levelProperty = sharedColumns.levelProperty;
const implicitStaffId = sharedColumns.implicitStaffId;

const serialNo = {
  data: {
    field: "serialNo",
    key: "serialNo",
    inputType: "number",
    title: "流水號",
    validator: VALIDATOR.isValidNone,
    hasFunctionality: { create: false, edit: false },
    isRequiredToInput: { create: false, edit: true },
  },
  propertyOfTable: {
    width: 100,
    align: "left",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const uploadedAvatar = {
  data: {
    field: "uploadedAvatar",
    key: "uploadedAvatar",
    inputType: "file",
    placeholder: "選擇檔案",
    title: "上傳頭像",
    validator: VALIDATOR.isValidFile,
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: false, edit: false },
  },
  propertyOfTable: {
    visible: false,
  },
};

const staffId = {
  data: {
    field: "staffId",
    key: "staffId",
    inputType: "option",
    placeholder: "",
    title: "員工編號",
    validator: VALIDATOR.isValidString,
    options: {
      option: [],
      valueField: "staffId",
      textField: "cnName",
    },
    hasFunctionality: { create: true, edit: false },
    isRequiredToInput: { create: true, edit: false },
  },
  propertyOfTable: {
    width: 150,
    align: "left",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const cnName = {
  data: {
    field: "cnName",
    key: "cnName",
    inputType: "text",
    placeholder: "",
    title: "中文姓名",
    validator: VALIDATOR.isValidString,
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: true, edit: true },
  },
  propertyOfTable: {
    width: 150,
    align: "center",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const enName = {
  data: {
    field: "enName",
    key: "enName",
    inputType: "text",
    placeholder: "",
    title: "英文名稱",
    validator: VALIDATOR.isValidString,
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: true, edit: true },
  },
  propertyOfTable: {
    align: "left",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const sex = {
  data: {
    field: "sex",
    key: "sex",
    inputType: "option",
    placeholder: "",
    title: "性別",
    validator: VALIDATOR.isValidNumber,
    options: {
      option: INPUT_OPTIONS.StaffRecord.sex,
      valueField: "id",
      textField: "name",
    },
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: true, edit: true },
  },
  propertyOfTable: {
    width: 100,
    align: "center",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const birthDate = {
  data: {
    field: "birthDate",
    key: "birthDate",
    title: "出生日期",
    inputType: "date",
    placeholder: "YYYY-MM-DD",
    validator: VALIDATOR.isValidDate,
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: true, edit: true },
  },
  propertyOfTable: {
    width: 150,
    align: "center",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const citizenId = {
  data: {
    field: "citizenId",
    key: "citizenId",
    inputType: "text",
    placeholder: "1 碼英文 + 9 碼數字",
    title: "身份證/居留證",
    validator: VALIDATOR.isValidCitizenId,
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: true, edit: true },
  },
  propertyOfTable: {
    align: "left",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const bloodType = {
  data: {
    field: "bloodType",
    key: "bloodType",
    hasDatabase: false,
    inputType: "option",
    placeholder: "",
    title: "血型",
    validator: VALIDATOR.isValidNumber,
    options: {
      option: INPUT_OPTIONS.StaffRecord.bloodType,
      valueField: "id",
      textField: "name",
    },
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: true, edit: true },
  },
  propertyOfTable: {
    width: 100,
    align: "center",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const marriageStatus = {
  data: {
    field: "marriageStatus",
    key: "marriageStatus",
    inputType: "option",
    placeholder: "",
    title: "婚姻狀態",
    validator: VALIDATOR.isValidNumber,
    options: {
      option: INPUT_OPTIONS.StaffRecord.marriageStatus,
      valueField: "id",
      textField: "name",
    },
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: true, edit: true },
  },
  propertyOfTable: {
    width: 150,
    align: "center",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const note = {
  data: {
    field: "note",
    key: "note",
    hasDatabase: false,
    inputType: "text",
    placeholder: "",
    title: "備注",
    validator: VALIDATOR.isValidString,
    hasFunctionality: { create: true, edit: false },
    isRequiredToInput: { create: false, edit: false },
  },
  propertyOfTable: {
    width: 150,
    align: "center",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const uploadedFiles = {
  data: {
    field: "uploadedFiles",
    key: "uploadedFiles",
    hasDatabase: false,
    inputType: "file",
    placeholder: "選擇檔案",
    title: "上傳資料",
    validator: VALIDATOR.isValidFile,
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: true, edit: true },
  },
};

const arrivalDate = {
  data: {
    field: "arrivalDate",
    key: "arrivalDate",
    hasDatabase: false,
    inputType: "date",
    placeholder: "YYYY-MM-DD",
    title: "到職日",
    validator: VALIDATOR.isValidDate,
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: true, edit: true },
  },
  propertyOfTable: {
    width: 150,
    align: "center",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const resignationDate = {
  data: {
    field: "resignationDate",
    key: "resignationDate",
    hasDatabase: false,
    inputType: "date",
    placeholder: "YYYY-MM-DD",
    title: "離職日",
    validator: VALIDATOR.isValidDate,
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: true, edit: true },
  },
  propertyOfTable: {
    width: 150,
    align: "center",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const telephone = {
  data: {
    field: "telephone",
    key: "telephone",
    inputType: "text",
    placeholder: "0X-",
    title: "家用電話",
    validator: VALIDATOR.isValidTelephone,
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: false, edit: false },
  },
  propertyOfTable: {
    align: "center",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const cellphone = {
  data: {
    field: "cellphone",
    key: "cellphone",
    inputType: "text",
    placeholder: "09XX-XXX-XXX",
    title: "手機號碼",
    validator: VALIDATOR.isValidCellphone,
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: true, edit: true },
  },
  propertyOfTable: {
    align: "center",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const officialTelephone = {
  data: {
    field: "officialTelephone",
    key: "officialTelephone",
    inputType: "text",
    placeholder: "0X-",
    title: "officialTelephone",
    validator: VALIDATOR.isValidTelephone,
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: false, edit: false },
  },
  propertyOfTable: {
    align: "center",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const officialCellphone = {
  data: {
    field: "officialCellphone",
    key: "officialCellphone",
    inputType: "text",
    placeholder: "09XX-XXX-XXX",
    title: "officialCellphone",
    validator: VALIDATOR.isValidCellphone,
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: false, edit: false },
  },
  propertyOfTable: {
    align: "center",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const permanentAddr = {
  data: {
    field: "permanentAddr",
    key: "permanentAddr",
    inputType: "text",
    title: "戶籍地址",
    validator: VALIDATOR.isValidAddress,
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: false, edit: false },
  },
  propertyOfTable: {
    align: "left",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const contactAddr = {
  data: {
    field: "contactAddr",
    key: "contactAddr",
    inputType: "text",
    placeholder: "",
    title: "通訊地址",
    validator: VALIDATOR.isValidAddress,
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: false, edit: false },
  },
  propertyOfTable: {
    align: "left",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const emailAddr = {
  data: {
    field: "emailAddr",
    key: "emailAddr",
    inputType: "text",
    placeholder: "example@gmail.com",
    title: "電子信箱",
    validator: VALIDATOR.isValidEmail,
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: true, edit: true },
  },
  propertyOfTable: {
    align: "left",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const highestEducationLevel = {
  data: {
    field: "highestEducationLevel",
    key: "highestEducationLevel",
    hasDatabase: false,
    inputType: "text",
    placeholder: "",
    title: "最高學歷",
    options: {
      option: INPUT_OPTIONS.StaffRecord.lastEducationalInstitute,
      valueField: "id",
      textField: "name",
    },
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: true, edit: true },
  },
  propertyOfTable: {
    align: "left",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
    renderBodyCell: ({ row, column }) => {
      const lastEducationalInstitute = parseInt(row[column.field]);
      const formattedLastEducationalInstitute =
        INPUT_OPTIONS.StaffRecord.lastEducationalInstitute.find(
          (item) => item.id === lastEducationalInstitute
        ) || "";
      return <span>{formattedLastEducationalInstitute.name}</span>;
    },
  },
};

const languageAbilities = {
  data: {
    field: "languageAbilities",
    key: "languageAbilities",
    hasDatabase: false,
    inputType: "object",
    placeholder: "請選擇語言",
    title: "語文能力",
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: false, edit: false },
  },
  propertyOfTable: {
    align: "left",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const educations = {
  data: {
    field: "educations",
    key: "educations",
    hasDatabase: false,
    inputType: "object",
    placeholder: "",
    title: "學歷",
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: true, edit: true },
  },
  propertyOfTable: {
    align: "left",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const workExperiences = {
  data: {
    field: "workExperiences",
    key: "workExperiences",
    hasDatabase: false,
    inputType: "object",
    placeholder: "",
    title: "工作經歷",
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: true, edit: true },
  },
  propertyOfTable: {
    align: "left",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const certificates = {
  data: {
    field: "certificates",
    key: "certificates",
    hasDatabase: false,
    inputType: "object",
    placeholder: "",
    title: "證照資格",
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: true, edit: true },
  },
  propertyOfTable: {
    align: "left",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const army = {
  data: {
    field: "army",
    key: "army",
    inputType: "option",
    placeholder: "",
    title: "服役情況",
    validator: VALIDATOR.isValidArmy,
    options: {
      option: INPUT_OPTIONS.StaffRecord.army,
      valueField: "id",
      textField: "name",
    },
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: true, edit: true },
  },
  propertyOfTable: {
    width: 150,
    align: "center",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const jobStatus = {
  data: {
    field: "jobStatus",
    key: "jobStatus",
    inputType: "option",
    placeholder: "",
    title: "就職狀態",
    validator: VALIDATOR.isValidNumber,
    options: {
      option: INPUT_OPTIONS.StaffRecord.jobStatus,
      valueField: "id",
      textField: "name",
    },
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: true, edit: true },
  },
  propertyOfTable: {
    width: 150,
    align: "center",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const jobTitleId = {
  data: {
    field: "jobTitleId",
    key: "jobTitleId",
    inputType: "option",
    placeholder: "",
    title: "職稱",
    validator: VALIDATOR.isValidNumber,
    // options: {
    //   option: INPUT_OPTIONS.StaffRecord.jobTitleId,
    //   valueField: "id",
    //   textField: "name",
    // },
    options: {
      option: [],
      valueField: "serialNo",
      textField: "chName",
    },
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: true, edit: true },
  },
  propertyOfTable: {
    width: 100,
    align: "left",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const jobGrade = {
  data: {
    field: "jobGrade",
    key: "jobGrade",
    hasDatabase: false,
    inputType: "option",
    placeholder: "",
    title: "職能",
    validator: VALIDATOR.isValidNumber,
    options: {
      option: INPUT_OPTIONS.StaffRecord.jobGrade,
      valueField: "id",
      textField: "name",
    },
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: true, edit: true },
  },
  propertyOfTable: {
    width: 100,
    align: "left",
    sortBy: "",
    ellipsis: {
      showTitle: true,
      lineClamp: 2,
    },
  },
};

const officialDepartmentId = {
  data: {
    field: "officialDepartmentId",
    key: "officialDepartmentId",
    inputType: "option",
    placeholder: "",
    title: "主要部門",
    validator: VALIDATOR.isValidString,
    options: { option: [], valueField: "departmentId", textField: "chName" },
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: false, edit: false },
  },
  propertyOfTable: {
    align: "left",
    sortBy: "",
    ellipsis: {
      showTitle: true,
      lineClamp: 2,
    },
  },
};

const specialIdentity = {
  data: {
    field: "specialIdentity",
    key: "specialIdentity",
    hasDatabase: false,
    inputType: "option",
    placeholder: "",
    title: "特殊身份別",
    validator: VALIDATOR.isValidNumber,
    options: {
      option: INPUT_OPTIONS.StaffRecord.specialIdentity,
      valueField: "id",
      textField: "name",
    },
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: true, edit: true },
  },
  propertyOfTable: {
    align: "left",
    sortBy: "",
    ellipsis: {
      showTitle: true,
      lineClamp: 2,
    },
  },
};

const emergencyContactPhone = {
  data: {
    field: "emergencyContactPhone",
    key: "emergencyContactPhone",
    hasDatabase: false,
    inputType: "text",
    placeholder: "09XX-XXX-XXX",
    title: "緊急聯絡人電話",
    validator: VALIDATOR.isValidCellphone,
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: true, edit: true },
  },
  propertyOfTable: {
    align: "center",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const emergencyContactName = {
  data: {
    field: "emergencyContactName",
    key: "emergencyContactName",
    hasDatabase: false,
    inputType: "text",
    placeholder: "",
    title: "緊急聯絡人姓名",
    validator: VALIDATOR.isValidString,
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: true, edit: true },
  },
  propertyOfTable: {
    align: "center",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const emergencyContactRelationship = {
  data: {
    field: "emergencyContactRelationship",
    key: "emergencyContactRelationship",
    hasDatabase: false,
    inputType: "text",
    placeholder: "",
    title: "緊急聯絡人關係",
    validator: VALIDATOR.isValidString,
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: true, edit: true },
  },
  propertyOfTable: {
    align: "center",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const lineId = {
  data: {
    field: "lineId",
    key: "lineId",
    inputType: "text",
    placeholder: "",
    title: "Line ID",
    validator: VALIDATOR.isValidString,
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: false, edit: false },
  },
  propertyOfTable: {
    align: "left",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const lineToken = {
  data: {
    field: "lineToken",
    key: "lineToken",
    inputType: "text",
    placeholder: "（暫無需提供）",
    title: "Line Token",
    validator: VALIDATOR.isValidString,
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: false, edit: false },
  },
  propertyOfTable: {
    align: "left",
    sortBy: "",
    ellipsis: { showTitle: true, ineClamp: 2 },
  },
};

const verificationCode = {
  data: {
    field: "verificationCode",
    key: "verificationCode",
    inputType: "text",
    placeholder: "",
    title: "認證碼",
    validator: VALIDATOR.isValidString,
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: false, edit: false },
  },
  propertyOfTable: {
    align: "left",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const priority = {
  data: {
    field: "priority",
    key: "priority",
    inputType: "number",
    placeholder: "",
    title: "部門優先級",
    validator: VALIDATOR.isValidNumber,
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: true, edit: true },
  },
  propertyOfTable: {
    align: "left",
    sortBy: "",
    ellipsis: { showTitle: true, ineClamp: 2 },
  },
};

export default {
  priority,
  accountId,
  actionCRUD,
  actionExtra,
  army,
  arrivalDate,
  birthDate,
  bloodType,
  cellphone,
  certificates,
  citizenId,
  cnName,
  contactAddr,
  departmentId,
  educations,
  emailAddr,
  emergencyContactName,
  emergencyContactPhone,
  emergencyContactRelationship,
  enName,
  highestEducationLevel,
  implicitStaffId,
  jobGrade,
  jobStatus,
  jobTitleId,
  languageAbilities,
  levelProperty,
  lineId,
  lineToken,
  marriageStatus,
  note,
  officialCellphone,
  officialDepartmentId,
  officialTelephone,
  permanentAddr,
  resignationDate,
  serialNo,
  sex,
  specialIdentity,
  staffId,
  telephone,
  uploadedAvatar,
  uploadedFiles,
  verificationCode,
  workExperiences,
};
