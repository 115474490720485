import store from "@/store";
import axios from "axios";
import {
  headers,
  // postRequestWithRetry,
  // postCRUDRequest,
} from "@/api/apiServices/commonService";
import { API_ROUTES } from "@/api/apiConfig";
import REQUEST_MODELS from "@/api/requestModels/Authentication/authenthication";

function checkNetworkError(error) {
  if (error.message === "Network Error") throw new Error("請洽資訊人員！");
}

const Authentication = {
  async login(requestData) {
    const reqModel = new REQUEST_MODELS.LOGIN(
      requestData.id,
      requestData.password
    );
    const url = API_ROUTES.AUTH.LOGIN;
    try {
      const response = await axios.post(url, reqModel, {
        headers: headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error in Authentication Login: ", error);
      const { response } = error;
      if (response) {
        switch (response.data.msg) {
          case "The ID or the password is wrong.":
            throw "登入失敗，請檢查 ID 和密碼是否正確！";
          case "There are too many times to verification failed. Try again a few minutes later.":
            throw "頻繁登入失敗，請稍後重試！";
          case "You tried to verify id and its corresponding password too frequently.":
            throw "頻繁登入失敗，請稍後重試！";
          default:
            break;
        }
      } else if (error.message === "Network Error")
        throw new Error("請洽資訊人員！");
    }
  },
  async logout() {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.LOGOUT(token);
    const url = API_ROUTES.AUTH.LOGOUT;
    try {
      const response = await axios.post(url, reqModel, {
        headers: headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error in Authentication Logout: ", error);
    }
  },
  async register(requestData) {
    const reqModel = new REQUEST_MODELS.REGISTER(
      requestData.id,
      requestData.password,
      requestData.email
    );
    const url = API_ROUTES.AUTH.REGISTER;
    try {
      const response = await axios.post(url, reqModel, {
        headers: headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error in Authentication Register: ", error);
      const { response } = error;
      if (response) {
        switch (response.data.msg) {
          case "The ID already exists.":
            throw "此 ID 已被註冊，請嘗試不同 ID！";
          case "The string for ID or the string for password is not valid.":
            throw "ID 或密碼無法通過！";
          default:
            throw response.data.msg;
        }
      } else if (error.message === "Network Error")
        throw new Error("請洽資訊人員！");
    }
  },
  async checkTokenValid() {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.CHECK_TOKEN_VALID(token);
    const url = API_ROUTES.AUTH.CHECK_ACCOUNT_LOGIN_BY_TOKEN;
    try {
      const response = await axios.post(url, reqModel, { headers: headers });
      return response.data;
    } catch (error) {
      const { response } = error;
      if (response) {
        throw new Error(response.data.msg);
      } else {
        checkNetworkError(error);
      }
    }
  },
};

export default {
  Authentication,
};
