<template>
  <BNavbar toggleable="lg" type="dark" class="main-navbar">
    <router-link :to="{ name: 'Dashboard' }" class="navbar-brand ms-3">
      <img src="@/assets/logo/logo.png" alt="Logo" class="main-navbar-logo" />
    </router-link>
    <BNavbarToggle target="main-navbar-collapse" />

    <BCollapse id="main-navbar-collapse" is-nav>
      <BNavbarNav class="ms-auto me-3">
        <!-- 權限模組 -->
        <BNavItem
          v-for="(data, i) in allDetailsOfPermissionItemsOfCurrentAccount"
          :key="i"
          class="mx-1 main-navbar-no-icon"
          @click="navigateToModule(data)"
        >
          {{ data.moduleName }}
        </BNavItem>

        <BNavItemDropdown
          class="mx-1 justify-content-end main-navbar-no-icon"
          right
        >
          <template #button-content>
            <BIcon icon="person-fill" />
          </template>
          <BDropdownItem href="#">帳號（未實作）</BDropdownItem>
          <BDropdownItem @click="onSubmitLogout()">登出</BDropdownItem>
        </BNavItemDropdown>
      </BNavbarNav>
    </BCollapse>
  </BNavbar>
</template>

<script>
import { EventBus } from "@/mixins/eventBus";
import { mapState, mapMutations, mapActions } from "vuex";
import SHORTCODE_ROUTER_PAIR from "@/router/shortcodeRouterPair";

export default {
  name: "NavBar",
  data() {
    return {
      moduleShortCodeRouterPair:
        SHORTCODE_ROUTER_PAIR.moduleShortCodeRouterPair,
      functionShortCodeRouterPair:
        SHORTCODE_ROUTER_PAIR.functionShortCodeRouterPair,
    };
  },
  computed: {
    ...mapState("SystemManagement", {
      allPermissionItemsByStaffID: (state) => state.allPermissionItemsByStaffID,
      allDetailsOfPermissionItemsOfCurrentAccount: (state) =>
        state.allDetailsOfPermissionItemsOfCurrentAccount,
      listOfSysModules: (state) => state.listOfSysModules,
      listOfSysMenus: (state) => state.listOfSysMenus,
      listOfSysFunctions: (state) => state.listOfSysFunctions,
    }),
  },
  watch: {},
  methods: {
    ...mapMutations(["SET_CURRENT_ROUTE"]),
    ...mapActions(["setCurrentRouteAndPermissionItemId"]),
    ...mapActions("SystemManagement", [
      "getHierarchicalPermissionListByAccountId",
      "getAllDetailsOfPermissionItemsByAccountID",
      "getAllSysModules",
      "getAllSysMenus",
      "getAllSysFunctions",
    ]),
    async getAllData() {
      await this.getAllSysModules();
      await this.getAllSysMenus();
      await this.getAllSysFunctions();
      await this.getHierarchicalPermissionListByAccountId();
      // await this.getAllDetailsOfPermissionItemsByAccountID();
    },
    /**
     * 導航至指定模組。
     * 根據模組的shortCode獲取對應的router名稱。
     * 若router名稱不是當前的router，則跳轉至新router，避免重複跳轉至當前router。
     * 同時根據router path設置獲取當前對應的permissionId（向api請求時需要提供當前頁面的permissionId）
     * @param {Object} data - 包含模組信息的數據對象。
     * @returns {Promise<void>}
     */
    async navigateToModule(data) {
      const routerName = await this.getRouterFromShortCode(
        data.moduleShortCode,
        this.moduleShortCodeRouterPair
      );
      if (this.$route.name !== routerName) {
        await this.setCurrentRouteAndPermissionItemId({ name: routerName });
        EventBus.$emit("sidebar:expand");
        this.$emit("select:module", this.$route);
      }
    },
    /**
     * 根據 shortCode 獲取對應的路由名稱。
     * @param {string} shortCode
     * @param {Object} SHORTCODE_ROUTER_PAIR - shortCode和router名稱的映射對象。
     * @returns {string|null} 返回對應的路由名稱。
     */
    getRouterFromShortCode(shortCode, SHORTCODE_ROUTER_PAIR) {
      return SHORTCODE_ROUTER_PAIR[shortCode] || null;
    },
    onSubmitLogout() {
      this.$router.logout();
    },
  },
  async created() {
    // 依次獲取所有數據，並設置當前router和權限項目ID（因為向api請求時需要提供當前的permissionId）。
    await this.getAllData();
    await this.setCurrentRouteAndPermissionItemId({ name: this.$route.name });
  },
};
</script>

<style scoped></style>
