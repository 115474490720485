<template>
  <BNav
    vertical
    :class="['m-0', 'px-3', 'w-100', 'main-navbar-content', { collapsed }]"
  >
    <BRow>
      <BCol class="col-12">
        <BAlert
          show
          variant="transparent"
          class="mt-2 mb-0 font-weight-bold main-sidebar-menu-alert"
        >
          <span v-show="!collapsed">{{ currentModule?.moduleName }}</span>
          <span>
            <BIcon
              class="text-center main-sidebar-toggle"
              @click="toggleSidebar"
              :icon="collapsed ? 'chevron-right' : 'chevron-left'"
            />
          </span>
        </BAlert>
      </BCol>
    </BRow>
    <div class="main-sidebar-menu-divider" />
    <BRow v-for="item in menuItems" :key="item.menuId">
      <BCol class="col-12">
        <BRow class="d-flex">
          <!-- 菜單項目按鈕 -->
          <BCol
            class="d-flex align-items-stretch"
            :class="collapsed ? 'col-12' : 'col-9'"
          >
            <BButton
              variant="transparent"
              class="mt-2 w-100 text-start font-weight-bold main-sidebar-menu-button menu-parent-font"
              @click="navigateToMenu(item)"
            >
              <div>
                <BIcon
                  v-if="collapsed"
                  :icon="item.icon"
                  shift-v="1"
                  class="mx-2"
                  style="color: transparent"
                />
                <span v-if="!collapsed">{{ item.menuName }}</span>
              </div>
            </BButton>
          </BCol>
          <!-- 展開按鈕 -->
          <BCol v-if="!collapsed" class="col-3 d-flex align-items-stretch">
            <BButton
              v-b-toggle="[`collapse-${item.menuShortCode}`]"
              variant="transparent"
              class="mt-2 w-100 text-start font-weight-bold main-sidebar-menu-button menu-parent-font"
            >
              <div class="d-flex justify-content-center align-items-center">
                <BIcon
                  :icon="
                    buttonCollapseState[item.menuShortCode]
                      ? 'chevron-up'
                      : 'chevron-down'
                  "
                  font-scale="0.9"
                  class="main-sidebar-toggle"
                />
              </div>
            </BButton>
          </BCol>
        </BRow>
        <!-- 子菜單區域 -->
        <BCollapse
          v-model="buttonCollapseState[item.menuShortCode]"
          :id="`collapse-${item.menuShortCode}`"
          class="my-2"
        >
          <BRow v-for="(func, index) in item.functionLists" :key="index">
            <BCol>
              <BButton
                v-b-toggle
                variant="transparent"
                class="w-100 text-start main-sidebar-menu-button menu-child-font"
                @click="navigateToFunction(func, item)"
              >
                {{ func.functionName }}
              </BButton>
            </BCol>
          </BRow>
        </BCollapse>
        <div class="main-sidebar-menu-divider" />
      </BCol>
    </BRow>
  </BNav>
</template>

<script>
import SHORTCODE_ROUTER_PAIR from "@/router/shortcodeRouterPair";

import { EventBus } from "@/mixins/eventBus";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "SideBarMenu",
  props: {
    collapsed: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      buttonCollapseState: {},
      hasResetButtonCollapseState: false,
      moduleShortCodeRouterPair:
        SHORTCODE_ROUTER_PAIR.moduleShortCodeRouterPair,
      menuShortCodeRouterPair: SHORTCODE_ROUTER_PAIR.menuShortCodeRouterPair,
      functionShortCodeRouterPair:
        SHORTCODE_ROUTER_PAIR.functionShortCodeRouterPair,
    };
  },
  watch: {
    collapsed(bool) {
      if (bool) this.resetButtonCollapState();
    },
    buttonCollapseState: {
      deep: true,
      immediate: true,
      handler(newValue) {
        const isMenuOpened = Object.values(newValue).some(
          (state) => state === true
        );
        this.$emit("toggle-sidebar-menu", isMenuOpened);
      },
    },
    allDetailsOfPermissionItemsOfCurrentAccount: {
      deep: true,
      immediate: true,
      handler(newValue, oldValue) {
        // 確保只有在首次變更時執行
        if (
          !newValue ||
          this._.isEqual(newValue, oldValue) ||
          !this.currentModule ||
          this.hasResetButtonCollapseState
        )
          return;

        this.hasResetButtonCollapseState = true;

        this.resetButtonCollapState();
      },
    },
  },
  computed: {
    ...mapState("SystemManagement", {
      allDetailsOfPermissionItemsOfCurrentAccount: (state) =>
        state.allDetailsOfPermissionItemsOfCurrentAccount,
    }),
    /**
     * 獲取當前模組的 shortCode。
     * @function currentModuleShortCode
     * @memberof VueComponent
     * @returns {string|null} 返回匹配的模組短代碼。
     */
    currentModuleShortCode() {
      const moduleName = this.$route.matched[1].name;
      return (
        this._.findKey(
          this.moduleShortCodeRouterPair,
          (value) => value === moduleName
        ) || null
      );
    },
    /**
     * 獲取當前模組的詳細信息。
     * @returns {Object|undefined} 返回當前模組的詳細信息對象。
     */
    currentModule() {
      const list = this._.cloneDeep(
        this.allDetailsOfPermissionItemsOfCurrentAccount
      );
      return this._.find(list, {
        moduleShortCode: this.currentModuleShortCode,
      });
    },
    /**
     * 獲取當前選單的 shortCode。
     * @function currentMenuShortCode
     * @memberof VueComponent
     * @returns {string|null} 返回匹配的選單短代碼。
     */
    currentMenuShortCode() {
      const menuName = this.$route.matched[2].name;
      return (
        this._.findKey(
          this.menuShortCodeRouterPair,
          (value) => value === menuName
        ) || null
      );
    },
    /**
     * 獲取當前選單的詳細信息。
     * @returns {Object|undefined} 返回當前選單的詳細信息對象。
     */
    currentMenu() {
      const list = this._.cloneDeep(this.currentModule?.menuLists);
      return this._.find(list, {
        menuShortCode: this.currentMenuShortCode,
      });
    },
    /**
     * 獲取當前模組的菜單項目。
     * @returns {Array<Object>} 返回菜單項目的數組。
     */
    menuItems() {
      return this.currentModule?.menuLists.map((item) => ({
        menuId: item.menuId,
        menuName: item.menuName,
        menuShortCode: item.menuShortCode,
        functionLists: item.functionLists.map((func) => ({
          functionId: func.functionId,
          functionName: func.functionName,
          functionShortCode: func.functionShortCode,
        })),
        icon: "arrow-right-short",
      }));
    },
  },
  methods: {
    ...mapMutations([]),
    ...mapActions(["setCurrentRouteAndPermissionItemId"]),
    toggleSidebar() {
      this.$emit("toggle-sidebar");
    },
    resetButtonCollapState() {
      const list = this._.cloneDeep(this.currentModule.menuLists);
      const menuShortCodeLists = this._.flatMap(
        this._.mapValues(list, (item) => item.menuShortCode)
      );
      this.buttonCollapseState = this._.mapValues(
        this._.keyBy(menuShortCodeLists, this._.identity),
        () => false
      );
    },
    /**
     * 導航到指定的菜單項目。
     * 根據菜單的shortCode獲取對應的router名稱。
     * 若router名稱不是當前的router，則跳轉至新router，避免重複跳轉至當前router。
     * 同時根據router path設置獲取當前對應的permissionId（向api請求時需要提供當前頁面的permissionId）
     * @param {Object} menu - 菜單項目對象，包含菜單的相關信息。
     * @returns {Promise<void>}
     */
    async navigateToMenu(menu) {
      this.buttonCollapseState[menu.menuShortCode] = true;
      const routerName = await this.getRouterFromShortCode(
        menu.menuShortCode,
        this.menuShortCodeRouterPair
      );
      if (this.$route.name !== routerName) {
        await this.setCurrentRouteAndPermissionItemId({ name: routerName });
        this.$emit("select:menu", menu, this.$route);
      }
    },
    /**
     * 導航到指定的功能項目。
     * 根據功能的shortCode獲取對應的router名稱
     * 若router名稱不是當前的router，則跳轉至新router，避免重複跳轉至當前router。
     * 同時根據router path設置獲取當前對應的permissionId（向api請求時需要提供當前頁面的permissionId）
     * @param {Object} func - 功能項目對象，包含功能的相關信息。
     * @param {Object} menu - 菜單項目對象，包含菜單的相關信息。
     * @returns {Promise<void>}
     */
    async navigateToFunction(func, menu) {
      const menuRouterName = await this.getRouterFromShortCode(
        menu.menuShortCode,
        this.menuShortCodeRouterPair
      );
      const formattedMenu = {
        name: menu.menuName,
        routeName: menuRouterName,
      };
      const functionRouterName = await this.getRouterFromShortCode(
        func.functionShortCode,
        this.functionShortCodeRouterPair
      );
      if (this.$route.name !== functionRouterName) {
        await this.setCurrentRouteAndPermissionItemId({
          name: functionRouterName,
        });
      }
      this.$emit("select:function", func, this.$route, formattedMenu);
    },
    /**
     * 根據 shortCode 獲取對應的路由名稱。
     * @param {string} shortCode
     * @param {Object} SHORTCODE_ROUTER_PAIR - shortCode和router名稱的映射對象。
     * @returns {string|null} 返回對應的路由名稱。
     */
    getRouterFromShortCode(shortCode, SHORTCODE_ROUTER_PAIR) {
      return SHORTCODE_ROUTER_PAIR[shortCode] || null;
    },
  },
  mounted() {
    EventBus.$on("navigateToMenu", this.navigateToMenu);
    EventBus.$on("navigateToFunction", this.navigateToFunction);
  },
};
</script>

<style scoped></style>
