import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import PlainLayout from "@/layouts/PlainLayout.vue";
import DashboardLayout from "@/layouts/DashboardLayout.vue";
import SystemManagementLayout from "@/layouts/SystemManagementLayout.vue";
import HumanResourceManagementLayout from "@/layouts/HumanResourceManagementLayout.vue";
import ProductManagementLayout from "@/layouts/ProductManagementLayout.vue";
import OrderManagementLayout from "@/layouts/OrderManagementLayout.vue";
import SystemManagementRouter from "./modules/SystemManagement";
import HumanResourceManagementRouter from "./modules/HumanResourceManagement";
import ProductManagementRouter from "./modules/ProductManagement";
import OrderManagementRouter from "./modules/OrderManagement";
import MembershipManagementLayout from "@/layouts/MembershipManagementLayout.vue";
import MembershipManagementRouter from "./modules/MembershipManagement";

Vue.use(VueRouter);

const authRoutes = [
  {
    path: "/auth",
    component: PlainLayout,
    children: [
      {
        path: "login",
        name: "Login",
        component: () => import("@/views/Authentication/LoginView.vue"),
        meta: {
          title: `登入 - ${process.env.VUE_APP_COMPANY_NAME}`,
        },
      },
      {
        path: "register",
        name: "Register",
        component: () => import("@/views/Authentication/RegisterView.vue"),
        meta: {
          title: `註冊 - ${process.env.VUE_APP_COMPANY_NAME}`,
        },
      },
      {
        path: "password-recovery",
        name: "Password-Recovery",
        component: () =>
          import("@/views/Authentication/PasswordRecoveryView.vue"),
        meta: {
          title: `申請找回密碼 - ${process.env.VUE_APP_COMPANY_NAME}`,
        },
      },
    ],
  },
];

const dashboardRoutes = [
  {
    path: "/",
    redirect: "Dashboard",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardLayout,
    meta: {
      requiresAuth: true,
      title: `首頁 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
    children: [
      {
        path: "system-management",
        name: "System-Management",
        component: SystemManagementLayout,
        redirect: { name: "System-Management-Main" },
        meta: {
          title: `系統管理 - ${process.env.VUE_APP_COMPANY_NAME}`,
        },
        children: [
          ...SystemManagementRouter.SM10_101_Main,
          ...SystemManagementRouter.SM10_101_Routes,
          ...SystemManagementRouter.SM10_102_Routes,
          ...SystemManagementRouter.SM10_103_Routes,
          ...SystemManagementRouter.SM10_104_Routes,
        ],
      },
      {
        path: "human-resource-management",
        name: "Human-Resource-Management",
        component: HumanResourceManagementLayout,
        redirect: { name: "Human-Resource-Management-Main" },
        meta: {
          title: `人資管理 - ${process.env.VUE_APP_COMPANY_NAME}`,
        },
        children: [
          ...HumanResourceManagementRouter.HR11_111_Main,
          ...HumanResourceManagementRouter.HR11_111_Routes,
          ...HumanResourceManagementRouter.HR11_112_Routes,
          ...HumanResourceManagementRouter.HR11_113_Routes,
        ],
      },
      {
        path: "product-management",
        name: "Product-Management",
        component: ProductManagementLayout,
        redirect: { name: "Product-Management-Main" },
        meta: {
          title: `商品管理 - ${process.env.VUE_APP_COMPANY_NAME}`,
        },
        children: [
          ...ProductManagementRouter.PM12_121_Main,
          ...ProductManagementRouter.PM12_121_Routes,
          ...ProductManagementRouter.PM12_122_Routes,
          ...ProductManagementRouter.PM12_123_Routes,
          ...ProductManagementRouter.PM12_127_Routes,
          ...ProductManagementRouter.PM12_128_Routes,
        ],
      },
      {
        path: "membership-management",
        name: "Membership-Management",
        component: MembershipManagementLayout,
        redirect: { name: "Member-Management-Main" },
        meta: {
          title: `會員管理 - ${process.env.VUE_APP_COMPANY_NAME}`,
        },
        children: [
          ...MembershipManagementRouter.MB13_131_Main,
          ...MembershipManagementRouter.MB13_131_Routes,
        ],
      },
      {
        path: "order-management",
        name: "Order-Management",
        component: OrderManagementLayout,
        redirect: { name: "Order-Management-Main" },
        meta: {
          title: `訂單管理 - ${process.env.VUE_APP_COMPANY_NAME}`,
        },
        children: [
          ...OrderManagementRouter.OM14_141_Main,
          ...OrderManagementRouter.OM14_141_Routes,
        ],
      },
    ],
  },
];

const guestRoutes = [
  {
    path: "/jing-dian/personal-info/:id/:vc",
    name: "Personal-Information-Form",
    component: () =>
      import(
        "@/views/HumanResourceManagement/111-EmployeeInformationManagement/111_4/HR_111_4_PersonalInformationView"
      ),
    meta: {
      title: `個人資料填寫 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
];

const routes = [...authRoutes, ...dashboardRoutes, ...guestRoutes];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Token檢查間隔時間（以毫秒為單位）
const CHECK_INTERVAL = 3 * 60 * 1000; // 3分鐘
let tokenCheckTimer;

function stopTokenCheckTimer() {
  clearInterval(tokenCheckTimer);
  tokenCheckTimer = null;
}

function resetTokenCheckTimer() {
  clearInterval(tokenCheckTimer);
  tokenCheckTimer = setInterval(async () => {
    console.log("三分鐘自動判斷");
    await store.dispatch("checkTokenValid");
    if (!store.state.auth.isTokenValid) {
      if (router.currentRoute.name !== "Login") router.push({ name: "Login" });
      alert("頁面失效，請重新登入！");
    }
  }, CHECK_INTERVAL);
}

router.beforeEach(async (to, from, next) => {
  await store.dispatch("setTokenUserIDFromCookie");
  await store.dispatch("checkTokenValid");

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = store.getters.isAuthenticated();

  const authenticationPages = ["Login", "Register", "Password-Recovery"];
  const isAuthenticationPage = authenticationPages.includes(to.name);

  if (requiresAuth && !isAuthenticated) {
    if (from.name !== "Login") {
      next({ name: "Login" });
    } else if (to.name !== "Login") {
      alert("請先登入！");
    }
  } else {
    document.title = to.meta.title || `${process.env.VUE_APP_COMPANY_NAME}`;
    next();

    if (isAuthenticationPage) {
      // 停止計時器
      stopTokenCheckTimer();
    } else if (requiresAuth) {
      // 只有需要驗證的頁面重置計時器
      resetTokenCheckTimer();
    }
  }
});

router.logout = async () => {
  try {
    await store.dispatch("logout");
    await store.dispatch("setCurrentRouteAndPermissionItemId", {
      name: "Login",
    });
  } catch {
    alert("登出失敗！");
  }
};

export default router;
