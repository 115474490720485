import axios from "axios";
import _ from "lodash";

const headers = {
  "Content-Type": "application/json;charset=utf-8",
  "Access-Control-Allow-Origin": "*",
};

function cleanedRequestData({ requestData, requestDatum }) {
  // 遍歷 requestData 對象，將值為 "--請選擇--" 的屬性替換為 ""
  if (requestData) {
    // 單一 requestData 處理
    return _.mapValues(requestData, (value) =>
      value === "--請選擇--" ? "" : value
    );
  } else if (requestDatum) {
    // requestDatum 數組處理
    return requestDatum.map((requestData) =>
      _.mapValues(requestData, (value) => (value === "--請選擇--" ? "" : value))
    );
  }
  return {};
}

function replaceUrl(url, placeholder, value) {
  return url.replace(`{${placeholder}}`, value);
}

async function postRequestWithRetry(url, reqModel) {
  const maxAttempts = 1; // 最大重試次數
  const delay = 1000; // 延遲時間（毫秒）
  let attempts = 0; // 當前嘗試次數

  while (attempts < maxAttempts) {
    try {
      const response = await axios.post(url, reqModel, { headers });
      if (response.status === 200) {
        return response.data;
      } else {
        console.log(response.status);
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      // console.error(`Attempt ${attempts + 1} failed:`, error);
      attempts++;
      if (attempts < maxAttempts) {
        await new Promise((resolve) => setTimeout(resolve, delay)); // 延遲後重試
      } else {
        // throw new Error(`Exceeded maximum attempts (${maxAttempts})`);
      }
    }
  }
}

async function postCRUDRequest(url, reqModel) {
  try {
    const response = await axios.post(url, reqModel, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    const { response } = error;
    const customMessage = `
      <br />
      <br />RequestBody (前端自查使用)：<br />
      ${JSON.stringify(reqModel)}
    `;
    if (response) {
      const errorMessage = response.data.msg;
      throw new Error(`${errorMessage}${customMessage}`);
    } else {
      if (error.message === "Network Error") throw new Error("請洽資訊人員！");
      // throw new Error(`${error.message}${customMessage}`);
    }
  }
}

async function getCRUDRequest(url) {
  try {
    const response = await axios.get(url, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    const { response } = error;
    if (response) {
      const errorMessage = response?.data?.msg;
      throw new Error(errorMessage);
    } else {
      if (error?.message === "Network Error") throw new Error("請洽資訊人員！");
      // throw new Error(`${error.message}${customMessage}`);
    }
  }
}

export {
  headers,
  cleanedRequestData,
  replaceUrl,
  postRequestWithRetry,
  postCRUDRequest,
  getCRUDRequest,
};
