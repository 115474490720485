<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" v-for="(item, index) in items" :key="index">
        <router-link :to="item.to" :class="{ active: item.active }">
          {{ item.text }}
        </router-link>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: "BreadCrumb",
  props: {
    items: {
      type: Array,
      required: false,
    },
  },
};
</script>

<style scoped>
.breadcrumb {
  background-color: transparent;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.breadcrumb-item {
  display: inline-block;
  padding: 0;
  margin: 0;
}

.breadcrumb-item a {
  text-decoration: none;
  color: black;
}

.breadcrumb-item a.router-link-exact-active {
  color: #4f9edf;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  padding: 5 5px;
}
</style>
