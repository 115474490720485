// eslint-disable-next-line no-unused-vars
function isValidNone(id = null) {
  return true;
}

function isValidString(string) {
  return string !== null && string !== "" && string !== undefined;
}

function isValidNumber(string) {
  if (!string || isNaN(Number(string))) {
    return false;
  }
  return true;
}

function isValidBool(bool) {
  return typeof bool === "boolean";
}

function isValidArray(array) {
  return Array.isArray(array);
}

function isValidDate(dateString) {
  var regex = /^\d{4}-\d{2}-\d{2}$/;

  if (!regex.test(dateString)) {
    return false;
  }

  var parts = dateString.split("-");
  var year = parseInt(parts[0], 10);
  var month = parseInt(parts[1], 10);
  var day = parseInt(parts[2], 10);

  // Check if month and day are valid
  if (month < 1 || month > 12 || day < 1 || day > 31) {
    return false;
  }

  // Check for February's days in case of leap year
  if (month == 2) {
    var isLeapYear = (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
    if (day > 29 || (day > 28 && !isLeapYear)) {
      return false;
    }
  }

  return true;
}

function isValidFile(file) {
  return file?.name !== null && file?.name !== "" && file?.name !== undefined;
}

function isValidCitizenId(citizenId) {
  const regex = /^[A-Z]\d{9}$/;
  return regex.test(citizenId);
}

function isValidTelephone(telephone) {
  const regex = /^\d{9,10}$/;
  return regex.test(telephone);
}

function isValidCellphone(cellphone) {
  const regex = /^\d{10}$/;
  return regex.test(cellphone);
}

function isValidEmail(email) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

function isValidArmy(string) {
  string = String(string);
  if (!isValidString(string)) return false;
  const parts = string.split("，");
  parts[1] = "未完善";
  return (
    parts.length === 2 && isValidNumber(parts[0]) && isValidString(parts[1])
  );
}

function isValidAddress(address) {
  if (!isValidString(address)) return false;
  const parts = address.split("，");
  return (
    parts.length === 4 &&
    isValidNumber(parts[0]) &&
    isValidString(parts[1]) &&
    isValidString(parts[2]) &&
    isValidString(parts[3])
  );
}

function isValidStringLengthBetween4And6(string) {
  return typeof string === "string" && /^[\s\S]{4,6}$/.test(string);
}

function isValidStringLengthBetween4And8(string) {
  return typeof string === "string" && /^[\s\S]{4,8}$/.test(string);
}

function isValidVATId(string) {
  if (string == null || string === "") {
    return false;
  }
  // 統編提供的固定權重
  let weights = [1, 2, 1, 2, 1, 2, 4, 1];

  // 將字串拆分為數字陣列並檢查有效性
  let vatArray = string.split("").map(Number);
  if (vatArray.length !== 8 || vatArray.some(isNaN)) {
    return false; // 若長度不符或包含非數字，回傳 false
  }

  // 計算加權總和
  let sum = vatArray.reduce((acc, num, idx) => {
    let product = num * weights[idx];
    return acc + Math.floor(product / 10) + (product % 10);
  }, 0);

  // 總和能被 5 整除即為有效
  if (sum % 5 === 0) {
    return true;
  }

  // 統編第 7 位數為 7 的情況特殊處理
  if (vatArray[6] === 7) {
    return (sum + 1) % 5 === 0;
  }

  return false;
}

export default {
  isValidAddress,
  isValidArmy,
  isValidArray,
  isValidBool,
  isValidCellphone,
  isValidCitizenId,
  isValidDate,
  isValidEmail,
  isValidFile,
  isValidNone,
  isValidNumber,
  isValidString,
  isValidStringLengthBetween4And6,
  isValidStringLengthBetween4And8,
  isValidTelephone,
  isValidVATId,
};
