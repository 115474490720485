class CUSTOMER_SEARCH {
  constructor(
    token = "",
    customerId = "",
    customerType = 0,
    phone = "",
    vATId = "",
    chName = "",
    enName = "",
    skipRows = 0,
    nextRows = 0
  ) {
    this.token = String(token);
    this.customerId = String(customerId);
    this.customerType = parseInt(customerType);
    this.phone = String(phone);
    this.vATId = String(vATId);
    this.chName = String(chName);
    this.enName = String(enName);
    this.skipRows = parseInt(skipRows);
    this.nextRows = parseInt(nextRows);
  }
}
class CUSTOMER_CREATE {
  constructor(
    token = "",
    departmentSn = 0,
    customerType = 0,
    firstShopSN = 0,
    chName = "",
    enName = "",
    county = "",
    district = "",
    detailedAddress = "",
    vATId = "",
    titleOnInvoice = "",
    industryType = "",
    careerType = "",
    contacts = [],
    accountSettings = []
  ) {
    this.token = String(token);
    this.departmentSn = parseInt(departmentSn);
    this.customerType = parseInt(customerType);
    this.firstShopSN = parseInt(firstShopSN);
    this.chName = String(chName);
    this.enName = String(enName);
    this.county = String(county);
    this.district = String(district);
    this.detailedAddress = String(detailedAddress);
    this.vATId = String(vATId);
    this.titleOnInvoice = String(titleOnInvoice);
    this.industryType = String(industryType);
    this.careerType = String(careerType);
    this.contacts = Array.isArray(contacts) ? contacts : [];
    this.accountSettings = Array.isArray(accountSettings)
      ? accountSettings
      : [];
  }
}
class CUSTOMER_READ {
  constructor(token = "", customerSn = 0) {
    this.token = String(token);
    this.customerSn = parseInt(customerSn);
  }
}
class CONTACT_MODEL {
  constructor(
    token = "",
    sn = 0,
    customerSn = 0,
    brandSn = 0,
    chName = "",
    enName = "",
    county = "",
    district = "",
    detailedAddress = "",
    citizenId = "",
    residentCertificateId = "",
    passportId = "",
    jobTitle = "",
    cellphone = "",
    phone1 = "",
    phoneExtension1 = "",
    phone2 = "",
    phoneExtension2 = "",
    email = "",
    deliverMethod = 0,
    priority = 0,
    deliverEnv = 0,
    deliverRule = 0
  ) {
    this.token = String(token);
    this.sn = parseInt(sn);
    this.customerSn = parseInt(customerSn);
    this.brandSn = parseInt(brandSn);
    this.chName = String(chName);
    this.enName = String(enName);
    this.county = String(county);
    this.district = String(district);
    this.detailedAddress = String(detailedAddress);
    this.citizenId = String(citizenId);
    this.residentCertificateId = String(residentCertificateId);
    this.passportId = String(passportId);
    this.jobTitle = String(jobTitle);
    this.cellphone = String(cellphone);
    this.phone1 = String(phone1);
    this.phoneExtension1 = String(phoneExtension1);
    this.phone2 = String(phone2);
    this.phoneExtension2 = String(phoneExtension2);
    this.email = String(email);
    this.deliverMethod = parseInt(deliverMethod);
    this.priority = parseInt(priority);
    this.deliverEnv = parseInt(deliverEnv);
    this.deliverRule = parseInt(deliverRule);
  }
}
class CONTACT_CREATE {
  constructor(token = "", departmentSn = 0, customerSn = 0, contacts = []) {
    this.token = String(token);
    this.departmentSn = parseInt(departmentSn);
    this.customerSn = parseInt(customerSn);
    this.contacts = Array.isArray(contacts) ? contacts : [];
  }
}
class PRODUCT_CATEGORY_READ {
  constructor(token = "", sn = 0, sellStatus = 0) {
    this.token = String(token);
    this.sn = parseInt(sn);
    this.sellStatus = parseInt(sellStatus);
  }
}
class PRODUCT_CLASS_READ {
  constructor(token = "", sn = 0, sellStatus = 0) {
    this.token = String(token);
    this.sn = parseInt(sn);
    this.sellStatus = parseInt(sellStatus);
  }
}
class PRODUCT_TYPE_READ {
  constructor(token = "", sn = 0, sellStatus = 0) {
    this.token = String(token);
    this.sn = parseInt(sn);
    this.sellStatus = parseInt(sellStatus);
  }
}
class PRODUCT_WITH_ATTRIBUTE_TYPE_AND_VALUE_READ {
  constructor(token = "", product3_SN = 0) {
    this.token = String(token);
    this.product3_SN = parseInt(product3_SN);
  }
}

export default {
  CUSTOMER_SEARCH,
  CUSTOMER_CREATE,
  CUSTOMER_READ,
  CONTACT_MODEL,
  CONTACT_CREATE,
  PRODUCT_CATEGORY_READ,
  PRODUCT_CLASS_READ,
  PRODUCT_TYPE_READ,
  PRODUCT_WITH_ATTRIBUTE_TYPE_AND_VALUE_READ,
};
