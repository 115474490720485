import _ from "lodash";
import Vue from "vue";
import Vuex from "vuex";
// import _ from "lodash";
import router from "@/router";
import API_MODULES_AUTH from "@/api/apiServices/Authentication/authenthication";
import SystemManagement from "./modules/SystemManagement";
import HumanResourceManagement from "./modules/HumanResourceManagement";
import ProductManagement from "./modules/ProductManagement";
import OrderManagement from "./modules/OrderManagement";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    SystemManagement,
    HumanResourceManagement,
    ProductManagement,
    OrderManagement,
  },
  state: {
    auth: {
      userID: "",
      token: "",
      cookie: "",
      isTokenValid: false,
    },
    alertStatus: {
      showAlert: false,
      message: "",
      variant: "",
      icon: "",
    },
    viewBus: {
      selectedModuleID: null,
      selectedTemplateGroupID: null,
      selectedActualGroupID: null,
      selectedCompanySN: null,
      selectedAttribute: {
        type: null,
      },
      selectedPostProcessing: {
        type: null,
      },
      selectedProduct: {
        type: null,
        category: null,
        class: null,
        model: null,
      },
      selectedProductWithAttributeTypeConfiguration: {
        type1: null,
        type2: null,
        type3: null,
      },
      selectedProductWithPostProcessingTypeConfiguration: {
        type: null,
        unshownValues: null,
      },
    },
    currentRoute: null,
    currentPermissionItemId: null,
    isOverlayVisible: false,
    toResetTableCheckboxOption: false,
    customExcelTableLabels: {
      footerLeft: (top, bottom) => `紀錄 ${top} 至 ${bottom}`,
      first: "首頁",
      previous: "上一頁",
      next: "下一頁",
      last: "尾頁",
      footerRight: {
        selected: "選擇：",
        filtered: "過濾：",
        loaded: "載入：",
      },
      processing: "執行中",
      tableSetting: "表格設定",
      exportExcel: "匯出 Excel",
      importExcel: "匯入 Excel",
      back: "關",
      reset: "預設",
      sortingAndFiltering: "排序及過濾",
      sortAscending: "小至大排序",
      sortDescending: "大至小排序",
      near: "≒ 接近",
      exactMatch: "= 等於",
      notMatch: "≠ 不等於",
      greaterThan: "&gt; 大於",
      greaterThanOrEqualTo: "≥ 大於或等於",
      lessThan: "&lt; 少於",
      lessThanOrEqualTo: "≤ 少於或等於",
      regularExpression: "~ 正規表達式",
      customFilter: "過濾內容",
      listFirstNValuesOnly: (n) => `只列出 ${n} 項`,
      apply: "應用",
      noRecordIsRead: "沒有紀錄被讀取",
      readonlyColumnDetected: "不可更新唯讀紀錄",
      columnHasValidationError: (name, err) =>
        `紀錄欄位 ${name} 發生核實錯誤: ${err}`,
      noMatchedColumnName: "没有能配對之欄位",
      invalidInputValue: "輸入錯誤内容",
      missingKeyColumn: "找不到關鍵欄位",
      noRecordIndicator: "沒有紀錄",
    },
  },
  getters: {
    isAuthenticated: (state) => () => {
      // 會從getTokenFromCookie中去抓取token，如果token存在isAuthenticated為true；否則為false
      return !!state.auth.token;
    },
    /**
     * 抓取此頁面的 permissionID
     * @returns {string} permissionID
     */
    getPermissionID: () => (listOfPermissionIDs) => {
      const pathPart = router.currentRoute.path.split("/").pop();
      const matchedID =
        _.find(listOfPermissionIDs, (item) => item.startsWith(pathPart)) ||
        null;
      return matchedID;
    },
    inputState:
      () =>
      (requireData, data, isvalidated = null) => {
        if (
          data === null ||
          data === undefined ||
          data === "" ||
          (typeof data === "string" && data.trim() === "") ||
          data === "--請選擇--"
        ) {
          if (!requireData) {
            return null;
          }
          return false;
        } else {
          if (isvalidated) {
            // validator
            return isvalidated(data);
          }
        }
        return true;
      },
    initializeForm: () => (columns) => {
      const form = {};
      const filteredColumns = columns.filter((column) => column.key !== "e");
      filteredColumns.forEach((column) => {
        if (column.inputType === "checkbox") form[column.key] = true;
        else form[column.key] = null;
      });
      return form;
    },
    populateMissingProperties: () => (form, columns) => {
      // 遍歷 columns 中的屬性，如果 form 中缺少該屬性，則補充
      for (const column of columns) {
        const property = column["key"];
        if (!(property in form)) {
          form = {
            ...form,
            [property]: null,
          };
        }
      }
      return form;
    },
    isFormValidated:
      (state, getters) =>
      (form, columns, action = "Create") => {
        // 此method目的在於判斷form，必填項目是否都有被填寫（不能為空），如果不是必填項目可忽略
        for (const key in form) {
          if (key === "token" || key === "btn_act" || key === "e") continue;
          const column = columns.find((column) => column.key === key);
          const isRequiredToInput =
            action === "Create"
              ? column?.isRequiredToInput?.create
              : column?.isRequiredToInput?.edit;
          const hasDatabase = column?.hasDatabase; //TODO
          const isValidated = getters.inputState(
            isRequiredToInput,
            form[key],
            column?.validator
          );
          if (!isRequiredToInput) continue; //非必填，則忽略
          if (hasDatabase === false) continue; //非必填，則忽略 //TODO
          if (!isValidated) {
            console.log("form 必填項中，尚未填寫的項目：", key, form[key]);
            return false;
          }
        }
        return true;
      },
    getPropertyOfFormInputCreate:
      (state, getters) => (column, formOfCreate) => {
        const properties = {
          condition: !["checkbox", "option"].includes(column.inputType),
          type: ["checkbox", "option"].includes(column.inputType)
            ? "text"
            : column.inputType,
          disabled: !column.hasFunctionality.create,
          state: getters.inputState(
            column.isRequiredToInput.create,
            formOfCreate[column.key]
          ),
          class: {
            "is-valid": getters.inputState(
              column.isRequiredToInput.create,
              formOfCreate[column.key]
            ),
            "is-invalid": !getters.inputState(
              column.isRequiredToInput.create,
              formOfCreate[column.key]
            ),
          },
          min: 0,
          max: Infinity,
        };
        return properties;
      },
    /**
     * 根據指定的 key 在 columns 中查找並返回對應的列。
     * @param {string} key - 要查找的列的 key。
     * @param {Array<Object>} columns - 包含多個列定義的數組，每個列定義是一個對象。
     * @returns {Object|undefined} 返回對應的列對象，如果沒有找到則返回 undefined。
     */
    columnOf: () => (key, columns) => {
      return columns.find((column) => column.key === key);
    },
    /**
     * 根據列和 columns 陣列中的 key，獲取對應列的選項。
     * @returns {Function} 返回一個函數，用於根據指定的列 key 和 columns 取得對應的選項值。
     * @param {string} key - 欲查找的列的鍵值。
     * @param {Array<Object>} columns - 包含列定義的陣列。
     * @param {string|number} value - 欲查找的值。
     * @param {string|null} [customValueField=null] - 自定義的值字段名稱，若為 null 則使用預設的 valueField。
     * @param {string|null} [customTargetField=null] - 自定義的目標字段名稱，若為 null 則使用預設的 textField。
     * @returns {string|undefined} 找到的選項的目標字段值，若未找到則返回 undefined。
     */
    itemInColumnOf:
      () =>
      (
        key,
        columns,
        value,
        customValueField = null,
        customTargetField = null
      ) => {
        const column = columns.find((column) => column.key === key);
        const options = column.options || [];
        const { option, valueField, textField } = options;
        return _.find(option, {
          [customValueField ? customValueField : valueField]: value,
        })?.[customTargetField ? customTargetField : textField];
      },
    /**
     * 根據列和 columns 數組中的 key，獲取對應列的選項。
     * @returns {Function} 返回一個函數，該函數接收 column 和 columns 作為參數，並返回對應列的選項。
     */
    optionsByColumn: (state, getters) => (column, columns) => {
      const key = column.key;
      return getters.columnOf(key, columns)?.options;
    },
    updateOptionOfColumnOf: () => (key, options, columns) => {
      const cloneOptions = _.cloneDeep(options); // 防止覆蓋原始資料
      // 更新 columns 中的 options 属性
      const updatedColumns = columns.map((column) => {
        if (column.field === key) {
          column.options.option = cloneOptions;
        }
        return column;
      });
      return updatedColumns;
    },
    formatValue: () => (COLUMNS, list, data, columnName) => {
      const valueField = COLUMNS[columnName].data.options.valueField;
      const textField = COLUMNS[columnName].data.options.textField;
      const formattedData = _.find(list, { [valueField]: data });
      return formattedData ? formattedData[textField] : "-";
    },
    renameCRUDKey: () => (form) => {
      const { create, read, update, delete: del, ...rest } = form;
      return _.isUndefined(form.create)
        ? form
        : {
            actionCreate: create,
            actionRead: read,
            actionUpdate: update,
            actionDelete: del,
            ...rest,
          };
    },
    toHashOptions: () => (sourceOptions, valueField, textField) => {
      // 映射並排序資料，直接構建 key-value 對象
      return sourceOptions.reduce((acc, option) => {
        const value = option[valueField];
        const text = option[textField];
        acc[value] = text;
        return acc;
      }, {});
    },
    getTabIndexAfterPostAction:
      () => (newValue, oldValue, oldActiveTabIndex, keysToRefer) => {
        // console.log("newValue, oldValue: ", newValue, oldValue);
        const mainKeyToRefer = keysToRefer[0];

        // 確保 newValue 和 oldValue 為數組
        const safeNewValue = _.isArray(newValue) ? newValue : [];
        const safeOldValue = _.isArray(oldValue) ? oldValue : [];

        // 提取 sn 值，支援多個 key，拼接為一個字符串
        const extractUniqKeyList = (list, keys) => {
          return _.map(list, (item) => {
            return keys.map((key) => item[key]).join("_"); // 多鍵值拼接，使用 "_" 連接
          });
        };
        // 提取底線前的數字部分
        const extractNumberFromUniqKey = (sn) => {
          const parts = sn.split("_");
          return Number(parts[0]); // 返回拼接字符串的第一部分，即底線前的數字
        };

        // 使用 extractUniqKeyList 提取新舊數據的 sn 值
        const oldUniqKeyList = extractUniqKeyList(safeOldValue, keysToRefer);
        const newUniqKeyList = extractUniqKeyList(safeNewValue, keysToRefer);

        // 計算差異
        const addedMainKeyRefer = _.difference(
          newUniqKeyList,
          oldUniqKeyList
        ).map(extractNumberFromUniqKey);
        const removedMainKeyRefer = _.difference(
          oldUniqKeyList,
          newUniqKeyList
        ).map(extractNumberFromUniqKey);

        // console.log("addedMainKeyRefer:", addedMainKeyRefer);
        // console.log("removedMainKeyRefer:", removedMainKeyRefer);

        // 如果差異超過兩個，認為是完全不同的數據集
        if (addedMainKeyRefer.length + removedMainKeyRefer.length > 2) {
          console.log("不同的數據集，返回第一個tab");
          return 0;
        }

        // 新增操作，找到新增項目的位置
        if (addedMainKeyRefer.length === 1) {
          console.log("新增操作，找到新增項目的位置");
          const newIndex = _.findIndex(safeNewValue, {
            [mainKeyToRefer]: addedMainKeyRefer[0],
          });
          return newIndex !== -1 ? newIndex : safeNewValue.length - 1;
        }

        // 刪除操作，返回第一個tab
        if (removedMainKeyRefer.length === 1) {
          console.log("刪除操作，返回第一個tab");
          return 0;
        }

        // 調換索引情況，保持當前sn對應索引
        const activeItemSn = safeOldValue[oldActiveTabIndex][mainKeyToRefer];
        if (activeItemSn) {
          console.log("調換索引情況，保持當前sn對應索引");
          const newIndex = _.findIndex(safeNewValue, {
            [mainKeyToRefer]: activeItemSn,
          });
          return newIndex !== -1 ? newIndex : oldActiveTabIndex;
        }

        // 預設返回當前的tab索引
        console.log("返回當前的tab索引");
        return oldActiveTabIndex;
      },
  },
  mutations: {
    SET_OVERLAY_VISILBILITY(state, bool) {
      state.isOverlayVisible = bool;
    },
    SET_ALERT_DETAIL_MESSAGE(state, details) {
      state.alertStatus = {
        ...details,
        showAlert: true,
      };
      // state.alertStatus.icon = "exclamation-circle-fill";
      // state.alertStatus.message = message;
      // state.alertStatus.statusTitle = "訊息";
      // state.alertStatus.success = true;
      // state.alertStatus.variant = "danger";
    },
    SET_ALERT_STATUS_SHOW_ALERT(state, bool) {
      state.alertStatus.showAlert = bool;
    },
    SET_TO_RESET_TABLE_CHECKBOX_OPTION(state, bool) {
      state.toResetTableCheckboxOption = bool;
    },
    SET_CURRENT_ROUTE(state, route) {
      state.currentRoute = route;
    },
    SET_TOKEN(state, token) {
      state.auth.token = token;
    },
    SET_TOKEN_VALIDITY(state, validity) {
      state.auth.isTokenValid = validity;
    },
    SET_COOKIE(state, cookie) {
      state.auth.cookie = cookie;
    },
    SET_USERID(state, id) {
      state.auth.userID = id;
    },
    SET_SELECTED_MODULE_ID(state, stringID) {
      state.viewBus.selectedModuleID = stringID;
    },
    SET_SELECTED_ACTUAL_GROUP_ID(state, stringID) {
      state.viewBus.selectedActualGroupID = stringID;
    },
    SET_SELECTED_TEMPLATE_GROUP_ID(state, stringID) {
      state.viewBus.selectedTemplateGroupID = stringID;
    },
    SET_SELECTED_COMPANY_SN(state, serialNo) {
      state.viewBus.selectedCompanySN = serialNo;
    },
    SET_SELECTED_PRODUCT_TYPE(state, serialNo) {
      state.viewBus.selectedProduct.type = serialNo;
    },
    SET_SELECTED_PRODUCT_CATEGORY(state, serialNo) {
      state.viewBus.selectedProduct.category = serialNo;
    },
    SET_SELECTED_PRODUCT_CLASS(state, serialNo) {
      state.viewBus.selectedProduct.class = serialNo;
    },
    SET_SELECTED_PRODUCT_MODEL(state, serialNo) {
      state.viewBus.selectedProduct.model = serialNo;
    },
    SET_SELECTED_ATTRIBUTE_TYPE(state, object) {
      state.viewBus.selectedAttribute.type = object;
    },
    SET_SELECTED_POST_PROCESSING_TYPE(state, object) {
      state.viewBus.selectedPostProcessing.type = object;
    },
    SET_SELECTED_PRODUCT_WITH_ATTRIBUTE_TYPE_CONFIGURATION(state, object) {
      state.viewBus.selectedProductWithAttributeTypeConfiguration = object;
    },
    SET_SELECTED_PRODUCT_WITH_POST_PROCESSING_TYPE_CONFIGURATION(
      state,
      object
    ) {
      state.viewBus.selectedProductWithPostProcessingTypeConfiguration = object;
    },
  },
  actions: {
    // FREQUENTLY USED
    // ALERT
    showSuccessMessage({ state, commit }, message, manual = false) {
      commit("SET_ALERT_STATUS_SHOW_ALERT", true);
      state.alertStatus.icon = "check-circle-fill";
      state.alertStatus.message = message;
      state.alertStatus.statusTitle = "操作成功";
      state.alertStatus.success = true;
      state.alertStatus.variant = "success";
      if (!manual)
        setTimeout(() => {
          commit("SET_ALERT_STATUS_SHOW_ALERT", false);
        }, 5000);
    },
    showErrorMessage({ state }, message) {
      state.alertStatus.icon = "exclamation-triangle-fill";
      state.alertStatus.message = message;
      state.alertStatus.showAlert = true;
      state.alertStatus.statusTitle = "操作失敗";
      state.alertStatus.success = false;
      state.alertStatus.variant = "danger";
      setTimeout(() => {
        state.alertStatus.showAlert = false;
      }, 10000);
    },
    showSpecialMessage({ state, commit }, message, manual = false) {
      commit("SET_ALERT_STATUS_SHOW_ALERT", true);
      // state.alertStatus.icon = "exclamation-circle-fill";
      state.alertStatus.message = message;
      // state.alertStatus.statusTitle = "訊息";
      // state.alertStatus.success = true;
      // state.alertStatus.variant = "danger";
      if (!manual)
        setTimeout(() => {
          commit("SET_ALERT_STATUS_SHOW_ALERT", false);
        }, 5000);
    },
    showOverlay({ commit }) {
      commit("SET_OVERLAY_VISILBILITY", true);
    },
    hideOverlay({ commit }) {
      // 延時關閉overlay
      setTimeout(() => {
        commit("SET_OVERLAY_VISILBILITY", false);
      }, 500);
    },
    // NOTE: 只能傳遞一個 parameter，多餘一個 parameter 必須包裝成 object
    async postOneData(
      { dispatch },
      { form, actionCRUD, actionsAfterDataPosted }
    ) {
      const clonedForm = _.cloneDeep(form);
      let responseAllData = true;
      let errorMessage = "Something Wrong!";

      try {
        const responseData = await actionCRUD(clonedForm);
        if (responseData === undefined) {
          responseAllData = false;
        } else if (!responseData.success) {
          errorMessage = responseData.msg;
          responseAllData = false;
        }
      } catch (error) {
        errorMessage = error;
        responseAllData = false;
      }

      if (responseAllData) {
        await actionsAfterDataPosted();
      } else {
        dispatch("showErrorMessage", errorMessage);
      }
    },
    async postManyData(
      { getters, dispatch },
      { forms, actionCRUD, actionsAfterDataPosted }
    ) {
      let responseAllData = true;
      let errorMessage = "Something Wrong!";
      const MAX_CONCURRENT_REQUESTS = 1; // 最大並發請求數量
      let runningRequests = 0; // 當前正在運行的請求數量

      const asyncQueue = forms.map(async (form) => {
        let clonedForm = _.cloneDeep(form);
        // 如果當前正在運行的請求數量達到了最大並發數，則等待
        while (runningRequests >= MAX_CONCURRENT_REQUESTS) {
          await new Promise((resolve) => setTimeout(resolve, 1)); // 每隔100毫秒檢查一次
        }
        // 發送請求前增加計數器
        runningRequests++;
        try {
          clonedForm = getters.renameCRUDKey(clonedForm);
          const responseData = await actionCRUD(clonedForm);
          if (responseData === undefined) {
            responseAllData = false;
          } else if (!responseData.success) {
            errorMessage = responseData.msg;
            responseAllData = false;
          }
        } catch (error) {
          errorMessage = error;
          responseAllData = false;
        } finally {
          // 請求完成後減少計數器
          runningRequests--;
        }
      });

      // 等待所有非同步操作完成
      await Promise.all(asyncQueue);

      if (responseAllData) {
        await actionsAfterDataPosted();
      } else {
        dispatch("showErrorMessage", errorMessage);
      }
    },
    async setCurrentRouteAndPermissionItemId({ commit }, { name }) {
      try {
        // 確保導航操作只會執行一次
        if (router.currentRoute.name !== name) {
          await router.push({ name });
        }
      } catch (error) {
        // 捕獲錯誤並忽略
        // console.error("Navigation error:", error);
      } finally {
        commit("SET_CURRENT_ROUTE", router.currentRoute);
      }
    },
    // AUTHENTHICATION
    generateAndUpdateDocumentCookie({ dispatch }, token) {
      const expDate = new Date();
      expDate.setTime(expDate.getTime() + 12 * 60 * 60 * 1000); // 12 hours
      const cookie = `token=${token};expires=${expDate.toUTCString()};path=/;SameSite=Strict`;
      document.cookie = cookie;
      localStorage.setItem("token_expiry", expDate.toUTCString()); // 保存過期時間到local storage

      dispatch("setTokenUserIDFromCookie");
    },
    setTokenUserIDFromCookie({ dispatch, commit }) {
      let token = "";
      const cookies = document.cookie.split(";").map((cookie) => cookie.trim());
      for (const cookie of cookies) {
        token = cookie.split("token=")[1];
      }
      // TIPS
      // const userID = dispatch("getUserIDFromToken", token); 不能直接這樣獲取value
      // 在 Vuex 中，dispatch 方法是用於觸發 action 的，並且 action 是非同步執行的，所以不能直接通過 dispatch 獲取返回值。但是你可以通過 return 語句在 action 中返回一個 Promise，然後在調用 dispatch 的地方使用 then 來獲取返回值。
      commit("SET_COOKIE", document.cookie);
      commit("SET_TOKEN", token);
      dispatch("setUserIDFromToken", token);
    },
    setUserIDFromToken({ commit }, token) {
      const parts = token?.split("|");
      commit("SET_USERID", parts?.length > 1 ? parts[0] : "");
    },
    resetTokenAndCookie({ commit }) {
      const now = new Date();
      now.setTime(now.getTime() - 1);
      const expires = now.toUTCString();
      document.cookie = "token=; expires=" + expires + "; path=/";
      commit("SET_COOKIE", document.cookie);
      commit("SET_TOKEN", "");
      commit("SET_TOKEN_VALIDITY", false);
    },
    async login({ getters, dispatch }, data) {
      const requestData = { ...data };
      const responseData =
        await API_MODULES_AUTH.Authentication.login(requestData);
      if (responseData?.msg) {
        await dispatch("generateAndUpdateDocumentCookie", responseData.msg);
        if (getters.isAuthenticated()) {
          await dispatch("setCurrentRouteAndPermissionItemId", {
            name: "Dashboard",
          });
        }
      }
      return responseData;
    },
    async logout({ state, commit, dispatch }) {
      if (!state.auth.token || !state.auth.isTokenValid) {
        commit("SET_TOKEN_VALIDITY", false);
        return undefined;
      } else {
        try {
          const responseData = await API_MODULES_AUTH.Authentication.logout();
          return responseData;
        } catch (error) {
          console.error(error);
        } finally {
          dispatch("resetTokenAndCookie");
        }
      }
    },
    async register(context, data) {
      const requestData = { ...data };
      const responseData =
        await API_MODULES_AUTH.Authentication.register(requestData);
      return responseData;
    },
    async checkTokenValid({ state, commit, dispatch }) {
      if (!state.auth.token) commit("SET_TOKEN_VALIDITY", false);
      else {
        let haveError = false;
        try {
          await API_MODULES_AUTH.Authentication.checkTokenValid();
        } catch (error) {
          if (error) haveError = true;
        }

        if (haveError) dispatch("resetTokenAndCookie");
        else commit("SET_TOKEN_VALIDITY", true);
      }
    },
    /**
     * 檢查 API 回應是否有效
     * @param {Object} responseData - 從伺服器接收到的回應資料
     * @returns {boolean} - 如果回應有效且可處理，返回 true；否則返回 false
     */
    isResponseValid(context, responseData) {
      // 回應的成功的 HTTP CODE status
      const validStatusCodes = [200, 201];
      return (
        responseData &&
        responseData.success &&
        validStatusCodes.includes(responseData.status)
      );
    },
  },
});
