import _ from "lodash";
import API_MODULES from "@/api/apiServices/OrderManagement/order";

const state = {
  listOfIndustryTree: null,
  listOfCareerTree: null,
  listOfProductCategory: null,
  listOfProductClass: null,
  listOfProductType: null,
  listOfProductWithAttributeValue: null,
};

const getters = {};

const mutations = {
  SET_LIST_OF_INDUSTRY_TREE(state, payload) {
    state.listOfIndustryTree = payload;
  },
  SET_LIST_OF_CAREER_TREE(state, payload) {
    state.listOfCareerTree = payload;
  },
  SET_LIST_OF_PRODUCT_CATEGORY(state, payload) {
    state.listOfProductCategory = payload;
  },
  SET_LIST_OF_PRODUCT_CLASS(state, payload) {
    state.listOfProductClass = payload;
  },
  SET_LIST_OF_PRODUCT_TYPE(state, payload) {
    state.listOfProductType = payload;
  },
  SET_LIST_OF_PRODUCT_WITH_ATTRIBUTE_VALUE(state, payload) {
    state.listOfProductWithAttributeValue = payload;
  },
};

const actions = {
  // Customer
  /**
   * searchCustomersFuzzily - 模糊搜尋 客戶
   * createNewCustomer - 新增 客戶
   * readCustomerInfo - 取回 客戶資料
   */
  async searchCustomersFuzzily({ rootState }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.Customer.search(requestData);
    return responseData;
  },
  async createNewCustomer({ rootState }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.Customer.create(requestData);
    return responseData;
  },
  async readCustomerInfo({ rootState }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.Customer.read(requestData);
    return responseData;
  },
  // Contact
  /**
   * createNewContact - 新增 客戶的聯絡人
   */
  async createNewContact({ rootState }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.Contact.create(requestData);
    return responseData;
  },
  // VATId
  /**
   * searchVATId - 搜尋 統一編號
   */
  async searchVATId(context, vATId) {
    const responseData = await API_MODULES.VATId.search(vATId);
    return responseData;
  },
  // Industry
  /**
   * getAllIndustry - 取回 行業別
   */
  async getAllIndustry({ dispatch, commit }) {
    const responseData = await API_MODULES.Industry.getAll();

    const isValid = await dispatch("isResponseValid", responseData, {
      root: true,
    });
    let handleData;

    try {
      // 檢查回傳結果
      if (!isValid) {
        throw new Error(responseData?.msg || "未知錯誤");
      } else {
        handleData = responseData.result.data[0].nextTier;
      }
    } catch (error) {
      console.error(error?.message || "未知錯誤");
    }

    commit("SET_LIST_OF_INDUSTRY_TREE", _.defaultTo(handleData, []));
  },
  // Career
  /**
   * getAllCareer - 取回 職業別
   */
  async getAllCareer({ dispatch, commit }) {
    const responseData = await API_MODULES.Career.getAll();

    const isValid = await dispatch("isResponseValid", responseData, {
      root: true,
    });
    let handleData;

    try {
      // 檢查回傳結果
      if (!isValid) {
        throw new Error(responseData?.msg || "未知錯誤");
      } else {
        handleData = responseData.result.data[0].nextTier;
      }
    } catch (error) {
      console.error(error?.message || "未知錯誤");
    }

    commit("SET_LIST_OF_CAREER_TREE", _.defaultTo(handleData, []));
  },
  // ProductCategory
  /**
   * readProductCategoryByProductType - 取回 大分類
   */
  async readProductCategoryByProductType(
    { dispatch, commit, rootState },
    data
  ) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.ProductCategory.read(requestData);

    const isValid = await dispatch("isResponseValid", responseData, {
      root: true,
    });
    let handleData;

    try {
      // 檢查回傳結果
      if (!isValid) {
        throw new Error(responseData?.msg || "未知錯誤");
      } else {
        handleData = responseData.result.data[0];
      }
    } catch (error) {
      console.error(error?.message || "未知錯誤");
    }

    commit("SET_LIST_OF_PRODUCT_CATEGORY", _.defaultTo(handleData, []));
  },
  // ProductClass
  /**
   * readProductClassByProductCategory - 取回 小分類
   */
  async readProductClassByProductCategory(
    { dispatch, commit, rootState },
    data
  ) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.ProductClass.read(requestData);

    const isValid = await dispatch("isResponseValid", responseData, {
      root: true,
    });
    let handleData;

    try {
      // 檢查回傳結果
      if (!isValid) {
        throw new Error(responseData?.msg || "未知錯誤");
      } else {
        handleData = responseData.result.data[0];
      }
    } catch (error) {
      console.error(error?.message || "未知錯誤");
    }

    commit("SET_LIST_OF_PRODUCT_CLASS", _.defaultTo(handleData, []));
  },
  // ProductType
  /**
   * readProductTypeByProductClass - 取回 商品
   */
  async readProductTypeByProductClass({ dispatch, commit, rootState }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.ProductType.read(requestData);

    const isValid = await dispatch("isResponseValid", responseData, {
      root: true,
    });
    let handleData;

    try {
      // 檢查回傳結果
      if (!isValid) {
        throw new Error(responseData?.msg || "未知錯誤");
      } else {
        handleData = responseData.result.data[0];
      }
    } catch (error) {
      console.error(error?.message || "未知錯誤");
    }

    commit("SET_LIST_OF_PRODUCT_TYPE", _.defaultTo(handleData, []));
  },
  // ProductWithAttributeTypeAndValue
  /**
   * readProductWithAttributeValueByProductSn - 取回 定義和定義值
   */
  async readProductWithAttributeValueByProductSn(
    { dispatch, commit, rootState },
    data
  ) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData =
      await API_MODULES.ProductWithAttributeTypeAndValue.read(requestData);

    const isValid = await dispatch("isResponseValid", responseData, {
      root: true,
    });
    let handleData;

    try {
      // 檢查回傳結果
      if (!isValid) {
        throw new Error(responseData?.msg || "未知錯誤");
      } else {
        handleData = responseData.result.data[0].attributes;
      }
    } catch (error) {
      console.error(error?.message || "未知錯誤");
    }

    commit(
      "SET_LIST_OF_PRODUCT_WITH_ATTRIBUTE_VALUE",
      _.defaultTo(handleData, [])
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
