// 訂單管理主頁 Order Management Main
const OM14_141_Main = [
  {
    path: "",
    name: "Order-Management-Main",
    component: () => import("@/views/Overall/OverallMenuListView.vue"),
    meta: {
      title: `訂單管理 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
];

// 訂單資料管理 Order Information Management 141
const OM14_141_Routes = [
  {
    path: "order-information-management",
    name: "Order-Information-Management",
    component: () => import("@/views/Overall/OverallFunctionListView.vue"),
    meta: {
      title: `訂單資料管理 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    // 新增訂單 141_1
    path: "141_1",
    name: "New-Order-Creation",
    component: () =>
      import(
        "@/views/OrderManagement/141-InformationOrderManagement/141_1/OM_141_1_CreateOrderView.vue"
      ),
    meta: {
      title: `新增訂單 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    // 查詢訂單 141_2
    path: "141_2",
    name: "Search-Order-Management",
    component: () =>
      import(
        "@/views/OrderManagement/141-InformationOrderManagement/141_2/OM_141_2_SearchOrderView.vue"
      ),
    meta: {
      title: `查詢訂單 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
];

export default { OM14_141_Main, OM14_141_Routes };
