// 會員管理主頁 Member Management Main
const MB13_131_Main = [
  {
    path: "",
    name: "Member-Management-Main",
    component: () => import("@/views/Overall/OverallMenuListView.vue"),
    meta: {
      title: `會員管理 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
];

// 會員資料管理 Member Information Management 131
const MB13_131_Routes = [
  {
    path: "member-information-management",
    name: "Member-Information-Management",
    component: () => import("@/views/Overall/OverallFunctionListView.vue"),
    meta: {
      title: `會員資料管理 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    // 新增會員 131_1
    path: "131_1",
    name: "Individual-Membership-Management",
    component: () =>
      import(
        "@/views/MembershipManagement/131-InformationMemberManagement/131_1/MB_131_1_CreateMemberView.vue"
      ),
    meta: {
      title: `新增會員 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  // {
  //   // 修改會員 131_2
  //   path: "131_2",
  //   name: "Edit-Membership-Management",
  //   component: () =>
  //     import(
  //       "@/views/MembershipManagement/131-InformationMemberManagement/131_2/MB_131_2_EditMemberView.vue"
  //     ),
  //   meta: {
  //     title: `修改會員資料 - ${process.env.VUE_APP_COMPANY_NAME}`,
  //   },
  // },
  {
    // 查詢會員 131_2
    path: "131_2",
    name: "Search-Membership-Management",
    component: () =>
      import(
        "@/views/MembershipManagement/131-InformationMemberManagement/131_2/MB_131_2_SearchMemberView.vue"
      ),
    meta: {
      title: `查詢會員資料 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
];

export default { MB13_131_Main, MB13_131_Routes };
