<template>
  <BContainer class="dashboard-layout" style="margin: 0; padding: 0">
    <NavBar @select:module="handleModuleClicked" />
    <BContainer fluid style="width: 100vw">
      <BRow>
        <!-- 側邊欄 -->
        <BCol
          :class="[
            'main-content',
            {
              'main-sidebar': $route.name === 'Dashboard',
              'main-sidebar-menu': $route.name !== 'Dashboard',
            },
            {
              'col-1': collapsed,
              'col-2': !collapsed,
            },
          ]"
        >
          <SideBar
            v-if="$route.name === 'Dashboard'"
            :collapsed="collapsed"
            @toggle-sidebar="toggleSidebar"
            @toggle-sidebar-menu="toggleSidebarMenu"
          />
          <SideBarMenu
            v-else
            :collapsed="collapsed"
            @toggle-sidebar="toggleSidebar"
            @toggle-sidebar-menu="toggleSidebarMenu"
            @select:menu="handleMenuClicked"
            @select:function="handleFunctionClicked"
          />
        </BCol>

        <!-- 主要内容區 -->
        <BCol
          :class="[
            'main-content',
            {
              'col-11': collapsed,
              'col-10': !collapsed,
            },
          ]"
        >
          <MainContainer>
            <template #main-content>
              <BreadCrumb :items="items" />
              <!-- <BBreadcrumb :items="items" class="main-breadcrumb" /> -->
            </template>
          </MainContainer>

          <router-view></router-view>
        </BCol>
      </BRow>
      <BRow>
        <BCol class="col-12">
          <!-- <Footer /> -->
        </BCol>
      </BRow>
    </BContainer>
  </BContainer>
</template>

<script>
import BreadCrumb from "../components/dashboard/BreadCrumb.vue";
import MainContainer from "@/components/containers/MainContainer.vue";
import NavBar from "../components/dashboard/NavBar.vue";
import SideBar from "../components/dashboard/SideBar.vue";
import SideBarMenu from "../components/dashboard/SideBarMenu.vue";
// import Footer from "../components/dashboard/Footer.vue";
import SHORTCODE_ROUTER_PAIR from "@/router/shortcodeRouterPair";
import { EventBus } from "@/mixins/eventBus";
import { mapState } from "vuex";

export default {
  name: "DashboardLayout",
  components: {
    BreadCrumb,
    MainContainer,
    NavBar,
    SideBar,
    SideBarMenu,
    // Footer,
  },
  watch: {
    "$route.name": {
      deep: true,
      immediate: true,
      handler() {
        this.updateBreadcrumb();
        // if (newValue === "Dashboard") {
        //   this.resetItems();
        //   this.routeDetails = {
        //     module: null,
        //     menu: null,
        //     function: null,
        //   };
        // }
      },
    },
  },
  data() {
    return {
      collapsed: false,
      moduleShortCodeRouterPair:
        SHORTCODE_ROUTER_PAIR.moduleShortCodeRouterPair,
      menuShortCodeRouterPair: SHORTCODE_ROUTER_PAIR.menuShortCodeRouterPair,
      functionShortCodeRouterPair:
        SHORTCODE_ROUTER_PAIR.functionShortCodeRouterPair,
      items: [],
      routeDetails: {
        module: null,
        menu: null,
        function: null,
      },
    };
  },
  computed: {
    ...mapState("SystemManagement", {
      allDetailsOfPermissionItemsOfCurrentAccount: (state) =>
        state.allDetailsOfPermissionItemsOfCurrentAccount,
    }),
    currentModuleShortCode() {
      for (const code in this.moduleShortCodeRouterPair) {
        const moduleName = this.$route.matched[1].name;
        if (this.moduleShortCodeRouterPair[code] === moduleName) {
          return code;
        }
      }
      return null;
    },
    currentModule() {
      return this.allDetailsOfPermissionItemsOfCurrentAccount.find(
        (module) => module.moduleShortCode === this.currentModuleShortCode
      );
    },
  },
  methods: {
    resetItems() {
      // const dashboardDetails = {
      //   text: "首頁",
      //   to: this.$router.resolve({ name: "Dashboard" }).href,
      //   type: "Dashboard",
      // };
      this.items = [];
      // this.items.push(dashboardDetails);
    },
    updateBreadcrumb() {
      this.resetItems();
      const matchedRoutes = this.$route.matched;

      // 定義一個函數來處理 breadcrumb 項目的生成
      const generateBreadcrumbItems = (routes) => {
        routes.forEach((record) => {
          const { name, meta } = record;
          this.items.push({
            text: meta.title ? meta.title.split(" - ")[0] : name,
            to: this.$router.resolve({ name }).href,
            name: name,
          });
        });
      };

      if (matchedRoutes.length >= 3) {
        const result = matchedRoutes[2].path.replace(matchedRoutes[1].path, "");
        // 如果 result 是 "/"，則排除最後一項路由（代表為相同一個頁面，只是因為redirect）
        if (result === "/") {
          generateBreadcrumbItems(matchedRoutes.slice(0, -1));
        } else {
          generateBreadcrumbItems(matchedRoutes);
        }
      } else {
        generateBreadcrumbItems(matchedRoutes);
      }

      this.updateActiveItem(this.$route);
    },
    handleModuleClicked() {
      this.updateBreadcrumb();
    },
    handleMenuClicked() {
      this.updateBreadcrumb();
    },
    handleFunctionClicked() {
      this.updateBreadcrumb();
    },
    toggleSidebarMenu(isMenuOpened) {
      if (this.collapsed && isMenuOpened) {
        this.collapsed = false;
      }
    },
    toggleSidebar() {
      this.collapsed = !this.collapsed;
    },
    updateActiveItem(route) {
      this.items.forEach((item) => {
        item.active = item.name === route.name;
        // console.log(`Item: ${item.text}, Active: ${item.active}`);
      });
    },
  },
  mounted() {
    this.updateBreadcrumb();
    EventBus.$on("sidebar:expand", () => {
      this.collapsed = false;
    });
  },
  created() {
    this.resetItems();
  },
  beforeDestroy() {
    EventBus.$off("sidebar:expand");
  },
  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //     vm.updateActiveItem(to);
  //   });
  // },
  // beforeRouteUpdate(to, from, next) {
  //   this.updateActiveItem(to);
  //   next();
  // },
};
</script>

<style scoped></style>
