// 人資管理主頁 Human Resource Management Main
const HR11_111_Main = [
  {
    path: "",
    name: "Human-Resource-Management-Main",
    component: () => import("@/views/Overall/OverallMenuListView.vue"),
    meta: {
      title: `人資管理 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
];

// 員工資料管理 Employee Information Management 111
const HR11_111_Routes = [
  {
    path: "employee-information-management",
    name: "Employee-Information-Management",
    component: () => import("@/views/Overall/OverallFunctionListView.vue"),
    meta: {
      title: `員工資料管理 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    // 員工資料維護 111_1
    path: "111_1",
    name: "Employee-Information-Maintenance",
    component: () =>
      import(
        "@/views/HumanResourceManagement/111-EmployeeInformationManagement/111_1/HR_111_1_EmployeeInformationMaintenanceView.vue"
      ),
    meta: {
      title: `員工資料維護 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    // 員工資料新增 111_2
    path: "111_2",
    name: "New-Employee-Creation",
    component: () =>
      import(
        "@/views/HumanResourceManagement/111-EmployeeInformationManagement/111_2/HR_111_2_NewEmployeeCreationView.vue"
      ),
    meta: {
      title: `員工資料新增 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    // 員工資料填寫 111_3
    path: "111_3",
    name: "Employee-Information-Entry",
    component: () =>
      import(
        "@/views/HumanResourceManagement/111-EmployeeInformationManagement/111_3/HR_111_3_EmployeeInformationEntryView.vue"
      ),
    meta: {
      title: `員工資料填寫 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
];

// 教育訓練 Education Training 112
const HR11_112_Routes = [
  {
    path: "xxxx",
    name: "xxxx",
    component: () => import("@/views/Overall/OverallFunctionListView.vue"),
    meta: {
      title: `員工資料管理 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    // xxxxx 111_1
    path: "112_1",
    name: "xxxxxx",
    // component: () =>
    //   import(
    //     "@/views/HumanResourceManagement/111-EmployeeInformationManagement/112_1/View.vue"
    //   ),
    meta: {
      title: `xxxxx - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
];

// 員工與組織管理 Employee and Organization Management 113
const HR11_113_Routes = [
  {
    path: "employee-and-organization-management",
    name: "Employee-And-Organization-Management",
    component: () => import("@/views/Overall/OverallFunctionListView.vue"),
    meta: {
      title: `員工與組織管理 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    // 員工與部門維護 113_1
    path: "113_1",
    name: "Employee-And-Department-Maintenance",
    component: () =>
      import(
        "@/views/HumanResourceManagement/113-EmployeeAndOrganizationManagement/113_1/HR_113_1_EmployeeDepartmentView.vue"
      ),
    meta: {
      title: `員工與部門維護 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
];

export default {
  HR11_111_Main,
  HR11_111_Routes,
  HR11_112_Routes,
  HR11_113_Routes,
};
