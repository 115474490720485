import _ from "lodash";
import API_MODULES from "@/api/apiServices/ProductManagement/product";
// import FORMATTER from "@/config/formatter";
// import INPUT_OPTIONS from "@/config/inputOptions/ProductManagement";
// import COLUMNS from "@/config/columns/ProductManagement";

const state = {
  listOfTypes: null,
  listOfCategories: null,
  listOfClasses: null,
  listOfModels: null,
  listOfAttributeTypes: null,
  listOfAttributeValues: null,
  listOfPostProcessingTypes: null,
  listOfPostProcessingTypesByAttribute: null,
  listOfPostProcessingPrimaryValues: null,
  listOfPostProcessingSecondaryValues: null,
  listOfPostProcessingTertiaryValues: null,
  listOfPostProcessingQuaternaryValues: null,
  listOfProductsWithConfigurations: null,
  listOfProductsWithAttributeTypesConfigurations: null,
  listOfProductsWithAttributeValuesConfigurations: null,
  listOfPrintUnits: null,
  listOfPrintingVendors: null,
  viewBus: {
    listOfAttributeValuesBySelectedProductAttributeType1: [],
    listOfProductAttributeTypesBySelectedProduct: null,
    listOfProductAttributeValuesBySelectedProductAttributeType1: null,
    listOfProductPostProcessingTypesByProduct: null,
    listOfProductPostProcessingValuesBySelectedProduct: null,
  },
};

const getters = {
  getHintByType: () => (type) => {
    if (type === "輸入數字" || type === "輸入文字") {
      return "輸入例子：長度 @ mm [ @ 為預留輸入位 ]";
    }
    return "";
  },
  formattedChNameOfAttributeValue: (state) => (data) => {
    const getUnitName = (unitSn) => {
      const unit = _.find(state.listOfPrintUnits, { sn: unitSn });
      return unit ? unit.chUnitName : ""; // 若找不到則返回空字串
    };

    // 如果 unitSn 是 0 或 null，直接返回原始數據；否則添加單位名稱
    return !data.unitSn
      ? { ...data }
      : { ...data, chName: `${data.chName}${getUnitName(data.unitSn)}` };
  },
  sortedProductAttributeTypeConfiguration: (state) => (list) => {
    const { listOfAttributeTypes } = state;

    // Step 1: 創建 sn 順序映射
    const snOrderMap = {};
    listOfAttributeTypes?.forEach((item, index) => {
      snOrderMap[item.sn] = index;
    });

    // Step 2: 根據 sn 排序 list(listOfProductsWithAttributeTypesConfigurations)
    const sortedListOfProductsWithAttributeTypesConfigurations = list.sort(
      (a, b) => {
        const aOrder =
          snOrderMap[a.attributeSn] !== undefined
            ? snOrderMap[a.attributeSn]
            : -1;
        const bOrder =
          snOrderMap[b.attributeSn] !== undefined
            ? snOrderMap[b.attributeSn]
            : -1;

        return aOrder - bOrder;
      }
    );

    return sortedListOfProductsWithAttributeTypesConfigurations;
  },
  sortedProductAttributeConfigurationByAttributeValue: (state) => (list) => {
    const { listOfAttributeValues } = state;

    // Step 1: 創建 sn 順序映射
    const snOrderMap = {};
    listOfAttributeValues?.forEach((item, index) => {
      snOrderMap[item.sn] = index;
    });

    // Step 2: 對 listOfProductsWithAttributeValuesConfigurations 進行排序，根據 attributeValueSn1、attributeValueSn2、attributeValueSn3 的順序
    const sortedListOfProductsWithAttributeValuesConfigurations = list.sort(
      (a, b) => {
        const aOrder = [
          snOrderMap[a.attributeValueSn1] !== undefined
            ? snOrderMap[a.attributeValueSn1]
            : -1,
          snOrderMap[a.attributeValueSn2] !== undefined
            ? snOrderMap[a.attributeValueSn2]
            : -1,
          snOrderMap[a.attributeValueSn3] !== undefined
            ? snOrderMap[a.attributeValueSn3]
            : -1,
        ];

        const bOrder = [
          snOrderMap[b.attributeValueSn1] !== undefined
            ? snOrderMap[b.attributeValueSn1]
            : -1,
          snOrderMap[b.attributeValueSn2] !== undefined
            ? snOrderMap[b.attributeValueSn2]
            : -1,
          snOrderMap[b.attributeValueSn3] !== undefined
            ? snOrderMap[b.attributeValueSn3]
            : -1,
        ];

        // 依次比較 attributeValueSn1, attributeValueSn2, attributeValueSn3 的順序
        for (let i = 0; i < aOrder.length; i++) {
          if (aOrder[i] !== bOrder[i]) {
            return aOrder[i] - bOrder[i];
          }
        }

        return 0; // 如果所有排序都相同
      }
    );

    return sortedListOfProductsWithAttributeValuesConfigurations;
  },
};

const mutations = {
  SET_LIST_OF_TYPES(state, list) {
    state.listOfTypes = list;
  },
  SET_LIST_OF_CATEGORIES(state, list) {
    const formattedList = _.sortBy(
      list.map((item) => {
        const orderByNumString = String(item["orderByNum"]).padStart(2, "0"); // 如果是個位數，補0
        return {
          ...item,
          orderByNumString,
        };
      }),
      ["orderByNumString"]
    );
    state.listOfCategories = formattedList;
  },
  SET_LIST_OF_CLASSES(state, list) {
    const formattedList = _.sortBy(
      list.map((item) => {
        const orderByNumString = String(item["orderByNum"]).padStart(2, "0"); // 如果是個位數，補0
        return {
          ...item,
          orderByNumString,
        };
      }),
      ["orderByNumString"]
    );
    state.listOfClasses = formattedList;
  },
  SET_LIST_OF_MODELS(state, list) {
    const formattedList = _.sortBy(
      list.map((item) => {
        const orderByNumString = String(item["orderByNum"]).padStart(2, "0"); // 如果是個位數，補0
        return {
          ...item,
          orderByNumString,
        };
      }),
      ["orderByNumString"]
    );
    state.listOfModels = formattedList;
  },
  SET_LIST_OF_ATTRIBUTE_TYPES(state, list) {
    list = _.sortBy(list, ["attributeId"]);
    state.listOfAttributeTypes = list;
  },
  SET_LIST_OF_ATTRIBUTE_VALUES(state, list) {
    // // 先根據自定義的排序函數進行排序
    // list = list.sort(FORMATTER.attributeValueSortByCustomOrder);

    // 然後僅按照屬性排序
    list = _.sortBy(list, ["belongsToAttribute"]);
    list = _.orderBy(list, ["orderByNum"]);

    state.listOfAttributeValues = list;
  },
  SET_LIST_OF_POST_PROCESSING_TYPES(state, list) {
    list = _.sortBy(list, ["postProcId"]);
    state.listOfPostProcessingTypes = list;
  },
  SET_LIST_OF_POST_PROCESSING_TYPES_BY_ATTRIBUTE(state, list) {
    state.listOfPostProcessingTypesByAttribute = list;
  },
  SET_LIST_OF_POST_PROCESSING_PRIMARY_VALUES(state, list) {
    list = _.sortBy(list, [
      "belongsToPostProc",
      "sectionOrder",
      "step",
      "titleStyle",
      "optionType",
      "optionStyle",
      "mainValueOrder",
    ]);
    state.listOfPostProcessingPrimaryValues = list;
  },
  SET_LIST_OF_POST_PROCESSING_SECONDARY_VALUES(state, list) {
    list = _.sortBy(list, [
      "belongsToMainValue",
      "step",
      "titleStyle",
      "optionType",
      "optionStyle",
      "primaryValueOrder",
    ]);
    state.listOfPostProcessingSecondaryValues = list;
  },
  SET_LIST_OF_POST_PROCESSING_TERTIARY_VALUES(state, list) {
    list = _.sortBy(list, [
      "belongsToMainValue",
      "belongsToPrimaryValue",
      "step",
      "titleStyle",
      "optionType",
      "optionStyle",
      "secondaryValueOrder",
    ]);
    state.listOfPostProcessingTertiaryValues = list;
  },
  SET_LIST_OF_POST_PROCESSING_QUATERNARY_VALUES(state, list) {
    list = _.sortBy(list, [
      "belongsToMainValue",
      "belongsToPrimaryValue",
      "belongsToSecondaryValue",
      "step",
      "titleStyle",
      "optionType",
      "optionStyle",
      "tertiaryValueOrder",
    ]);
    state.listOfPostProcessingQuaternaryValues = list;
  },
  SET_LIST_OF_PRODUCTS_WITH_CONFIGURATIONS(state, list) {
    state.listOfProductsWithConfigurations = list;
  },
  SET_LIST_OF_PRODUCTS_WITH_ATTRIBUTE_TYPES_CONFIGURATIONS(state, list) {
    state.listOfProductsWithAttributeTypesConfigurations = list;
  },
  SET_LIST_OF_PRODUCTS_WITH_ATTRIBUTE_VALUES_CONFIGURATIONS(state, list) {
    state.listOfProductsWithAttributeValuesConfigurations = list;
  },
  SET_LIST_OF_PRINT_UNITS(state, list) {
    list = _.sortBy(list, "chUnitName");
    state.listOfPrintUnits = list;
  },
  SET_LIST_OF_PRINTING_VENDORS(state, list) {
    const classes = [
      "badge-groupset-2-1",
      "badge-groupset-2-2",
      "badge-groupset-2-3",
      "badge-groupset-2-4",
      "badge-groupset-2-5",
    ];

    // 遍歷 list 並新增 class 屬性
    state.listOfPrintingVendors = list.map((vendor, index) => {
      return {
        ...vendor,
        class: `table-badge-style ${classes[index % classes.length]}`, // 使用 % 操作符來循環使用 classes
      };
    });
  },
  SET_LIST_OF_ATTRIBUTE_VALUES_BY_SELECTED_PRODUCT_ATTRIBUTE_TYPE_1(
    state,
    list
  ) {
    // // 先根據自定義的排序函數進行排序
    // list = list.sort(FORMATTER.attributeValueSortByCustomOrder);

    // 然後僅按照屬性排序
    list = _.sortBy(list, ["belongsToAttribute"]);
    list = _.orderBy(list, ["orderByNum"]);

    state.viewBus.listOfAttributeValuesBySelectedProductAttributeType1 = list;
  },
  SET_LIST_OF_PRODUCT_ATTRIBUTE_TYPES_BY_SELECTED_PRODUCT_ATTRIBUTE_1(
    state,
    list
  ) {
    list = _.sortBy(list, ["productAttributeDetails.orderByNum"]);
    state.viewBus.listOfProductAttributeTypesBySelectedProduct = list;
  },
  SET_LIST_OF_PRODUCT_ATTRIBUTE_VALUES_BY_SELECTED_PRODUCT_ATTRIBUTE_TYPE_1(
    state,
    list
  ) {
    state.viewBus.listOfProductAttributeValuesBySelectedProductAttributeType1 =
      list;
  },
  SET_LIST_OF_PRODUCT_POST_PROCESSING_TYPES_BY_SELECTED_PRODUCT(state, list) {
    state.viewBus.listOfProductPostProcessingTypesByProduct = list;
  },
  SET_LIST_OF_PRODUCT_POST_PROCESSING_VALUES_BY_SELECTED_PRODUCT(state, list) {
    state.viewBus.listOfProductPostProcessingValuesBySelectedProduct = list;
  },
};

const actions = {
  /**
   * 在完成「新增」、「修改」、「刪除」操作以後，需立即請求並且更新相關資料。
   * 最後重新獲取當前帳號的所有權限資料，即時更新畫面資料（不需要額外刷新頁面）。
   * @returns {Promise<void>}
   */
  async refreshDataByAction({ dispatch }, action) {
    await dispatch(action);
    await dispatch(
      // "SystemManagement/getAllDetailsOfPermissionItemsByAccountID",
      "SystemManagement/getHierarchicalPermissionListByAccountId",
      null,
      { root: true }
    );
  },
  async getAllProducts({ dispatch }) {
    await dispatch("getAllTypes");
    await dispatch("getAllCategories");
    await dispatch("getAllClasses");
    await dispatch("getAllModels");
  },
  // TYPE
  async getAllTypes({ commit }) {
    const responseData = await API_MODULES.Type.getAll();
    await commit("SET_LIST_OF_TYPES", _.defaultTo(responseData, []));
  },
  async createType({ rootState, rootGetters, dispatch }, data) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.Type.create(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllTypes");
    return responseData;
  },
  async readType({ rootState }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.Type.read(requestData);
    return responseData;
  },
  async updateType({ rootState, rootGetters, dispatch }, data) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.Type.update(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllTypes");
    return responseData;
  },
  async deleteType({ rootState, rootGetters, dispatch }, data) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.Type.delete(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllTypes");
    return responseData;
  },
  // CATEGORY
  async getAllCategories({ commit }) {
    const responseData = await API_MODULES.Category.getAll();
    await commit("SET_LIST_OF_CATEGORIES", _.defaultTo(responseData, []));
  },
  async createCategory({ rootState, rootGetters, dispatch }, data) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.Category.create(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllCategories");
    return responseData;
  },
  async readCategory({ rootState }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.Category.read(requestData);
    return responseData;
  },
  async updateCategory({ rootState, rootGetters, dispatch }, data) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.Category.update(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllCategories");
    return responseData;
  },
  async deleteCategory({ rootState, rootGetters, dispatch }, data) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.Category.delete(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllCategories");
    return responseData;
  },
  // CLASS
  async getAllClasses({ commit }) {
    const responseData = await API_MODULES.Class.getAll();
    commit("SET_LIST_OF_CLASSES", _.defaultTo(responseData, []));
  },
  async createClass({ rootState, rootGetters, dispatch }, data) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.Class.create(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllClasses");
    return responseData;
  },
  async updateClass({ rootState, rootGetters, dispatch }, data) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.Class.update(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllClasses");
    return responseData;
  },
  async deleteClass({ rootState, rootGetters, dispatch }, data) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.Class.delete(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllClasses");
    return responseData;
  },
  // MODEL
  async getAllModels({ commit }) {
    const responseData = await API_MODULES.Model.getAll();
    commit("SET_LIST_OF_MODELS", _.defaultTo(responseData, []));
  },
  async createModel({ rootState, rootGetters, dispatch }, data) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.Model.create(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllModels");
    return responseData;
  },
  async updateModel({ rootState, rootGetters, dispatch }, data) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.Model.update(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllModels");
    return responseData;
  },
  async deleteModel({ rootState, rootGetters, dispatch }, data) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.Model.delete(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllModels");
    return responseData;
  },
  // ATTRIBUTE TYPE
  async getAllAttributeTypes({ commit }) {
    const responseData = await API_MODULES.AttributeType.getAll();
    commit("SET_LIST_OF_ATTRIBUTE_TYPES", _.defaultTo(responseData, []));
  },
  async createAttributeType({ rootState, rootGetters, dispatch }, data) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.AttributeType.create(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllAttributeTypes");
    return responseData;
  },
  async updateAttributeType({ rootState, rootGetters, dispatch }, data) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.AttributeType.update(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllAttributeTypes");
    return responseData;
  },
  async deleteAttributeType({ rootState, rootGetters, dispatch }, data) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.AttributeType.delete(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllAttributeTypes");
    return responseData;
  },
  // ATTRIBUTE VALUE
  async getAllAttributeValues({ commit }) {
    const responseData = await API_MODULES.AttributeValue.getAll();
    commit("SET_LIST_OF_ATTRIBUTE_VALUES", _.defaultTo(responseData, []));
  },
  async getAllAttributeValuesByAttributeType({ rootState }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData =
      await API_MODULES.AttributeValue.getAllByAttributeType(requestData);
    const sortedResponseData = _.sortBy(responseData, ["orderByNum"]);
    return sortedResponseData;
  },
  async createAttributeValue(
    { rootState, rootGetters, getters, dispatch },
    data
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    data = getters.formattedChNameOfAttributeValue(data);
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.AttributeValue.create(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllAttributeValues");
    return responseData;
  },
  async createSetAttributeValue({ rootState, rootGetters, getters }, data) {
    // 不使用
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    data = getters.formattedChNameOfAttributeValue(data);
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.AttributeValue.create(
      requestData,
      permissionID
    );
    return responseData;
  },
  async createBatchAttributeValue(
    { rootState, rootGetters, getters, dispatch },
    dataList
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = dataList.map((data) => {
      data = getters.formattedChNameOfAttributeValue(data);
      return { ...data, token: rootState.auth.token };
    });

    const responseData = await API_MODULES.AttributeValue.createBatch(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllAttributeValues");
    return responseData;
  },
  async updateAttributeValue(
    { rootState, rootGetters, getters, dispatch },
    data
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );

    data = getters.formattedChNameOfAttributeValue(data);
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.AttributeValue.update(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllAttributeValues");
    return responseData;
  },
  async deleteAttributeValue({ rootState, rootGetters, dispatch }, data) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.AttributeValue.delete(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllAttributeValues");
    return responseData;
  },
  // POST PROCESSING TYPE
  async getAllPostProcessingTypes({ commit }) {
    const responseData = await API_MODULES.PostProcessingType.getAll();
    commit("SET_LIST_OF_POST_PROCESSING_TYPES", _.defaultTo(responseData, []));
  },
  async createPostProcessingType({ rootState, rootGetters, dispatch }, data) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.PostProcessingType.create(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllPostProcessingTypes");
    return responseData;
  },
  async updatePostProcessingType({ rootState, rootGetters, dispatch }, data) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.PostProcessingType.update(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllPostProcessingTypes");
    return responseData;
  },
  async deletePostProcessingType({ rootState, rootGetters, dispatch }, data) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.PostProcessingType.delete(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllPostProcessingTypes");
    return responseData;
  },
  async readByProductAttributePostProcessingType(
    { commit, rootState, dispatch },
    data
  ) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData =
      await API_MODULES.PostProcessingType.readByProductAttribute(requestData);

    const isValid = await dispatch("isResponseValid", responseData, {
      root: true,
    });
    let handleData;

    try {
      // 檢查回傳結果
      if (!isValid) {
        throw new Error(responseData?.msg || "未知錯誤");
      } else {
        handleData = responseData.result.data[0];
      }
    } catch (error) {
      console.error(error?.message || "未知錯誤");
    }
    commit(
      "SET_LIST_OF_POST_PROCESSING_TYPES_BY_ATTRIBUTE",
      _.defaultTo(handleData, [])
    );
  },
  // POST PROCESSING VALUE
  async getAllPostProcessingValues({ dispatch }) {
    await dispatch("getAllPostProcessingPrimaryValues");
    await dispatch("getAllPostProcessingSecondaryValues");
    await dispatch("getAllPostProcessingTertiaryValues");
    await dispatch("getAllPostProcessingQuaternaryValues");
  },
  // POST PROCESSING VALUE: PRIMARY
  async getAllPostProcessingPrimaryValues({ commit }) {
    const responseData = await API_MODULES.PostProcessingPrimaryValue.getAll();
    commit(
      "SET_LIST_OF_POST_PROCESSING_PRIMARY_VALUES",
      _.defaultTo(responseData, [])
    );
  },
  // async getAllPostProcessingPrimaryValues({ commit, dispatch }) {
  //   const responseData = await API_MODULES.PostProcessingPrimaryValue.getAll();
  //   const invalidEntries = await dispatch("getInvalidEntries", responseData);
  //   // console.log("invalidEntries: ", invalidEntries);
  //   // commit("SET_LIST_OF_POST_PROCESSING_PRIMARY_VALUES", _.defaultTo(responseData, []));
  //   if (invalidEntries.length === 0) {
  //     commit(
  //       "SET_LIST_OF_POST_PROCESSING_PRIMARY_VALUES",
  //       _.defaultTo(responseData, [])
  //     );
  //   } else {
  //     await dispatch(
  //       "postManyData",
  //       {
  //         forms: invalidEntries,
  //         actionCRUD: (data) =>
  //           dispatch("deletePostProcessingPrimaryValue", data),
  //         actionsAfterDataPosted: () => {}, // 沒有後續操作
  //       },
  //       { root: true }
  //     );
  //   }
  // },
  // getInvalidEntries(context, list) {
  //   // 將 list 整理為 groupedData
  //   function groupData(data) {
  //     const grouped = {};

  //     data.forEach((item) => {
  //       const { chName, primaryValue, secondaryValue } = item;

  //       // 初始化 chName
  //       if (!grouped[chName]) {
  //         grouped[chName] = {};
  //       }

  //       // 初始化 primaryValue
  //       if (!grouped[chName][primaryValue]) {
  //         grouped[chName][primaryValue] = {};
  //       }

  //       // 初始化 secondaryValue
  //       if (!grouped[chName][primaryValue][secondaryValue]) {
  //         grouped[chName][primaryValue][secondaryValue] = [];
  //       }

  //       grouped[chName][primaryValue][secondaryValue].push(item);
  //     });

  //     return grouped;
  //   }

  //   // 檢查 groupedData 中的無效條目
  //   function findInvalidEntries(groupedData) {
  //     const invalidEntries = [];

  //     Object.keys(groupedData).forEach((chName) => {
  //       const primaryGroup = groupedData[chName];

  //       // 檢查 primaryValue
  //       Object.keys(primaryGroup).forEach((primaryValue) => {
  //         const secondaryGroup = primaryGroup[primaryValue];

  //         // 如果 primaryValue 是空字符串，且該 chName 下存在其他 primaryValue
  //         if (primaryValue === "" && Object.keys(primaryGroup).length > 1) {
  //           if (primaryGroup[""] && typeof primaryGroup[""] === "object") {
  //             const values = Object.values(primaryGroup[""]);
  //             if (values.length === 1) {
  //               invalidEntries.push(...values[0]);
  //             }
  //           }
  //         }

  //         // 檢查 secondaryValue
  //         Object.keys(secondaryGroup).forEach((secondaryValue) => {
  //           const tertiaryGroup = secondaryGroup[secondaryValue];

  //           // 如果 secondaryValue 是空字符串，且該 primaryValue 下存在其他 secondaryValue
  //           if (
  //             secondaryValue === "" &&
  //             Object.keys(secondaryGroup).length > 1
  //           ) {
  //             if (
  //               secondaryGroup[""] &&
  //               typeof secondaryGroup[""] === "object"
  //             ) {
  //               const values = Object.values(secondaryGroup[""]);
  //               if (values.length === 1) {
  //                 invalidEntries.push(...values);
  //               }
  //             }
  //           }

  //           // 檢查 tertiaryValue
  //           if (Array.isArray(tertiaryGroup)) {
  //             tertiaryGroup.forEach((item) => {
  //               if (
  //                 item.tertiaryValue === "" &&
  //                 tertiaryGroup.length > 1 &&
  //                 tertiaryGroup.some((t) => t.tertiaryValue !== "")
  //               ) {
  //                 invalidEntries.push(item);
  //               }
  //             });
  //           }
  //         });
  //       });
  //     });

  //     return invalidEntries;
  //   }

  //   // 整理資料並檢查無效條目
  //   const groupedData = groupData(list);
  //   const invalidEntries = findInvalidEntries(groupedData);

  //   // 確保 invalidEntries 只有一層
  //   return invalidEntries.map((item) => ({ ...item }));
  // },
  async createPostProcessingPrimaryValue(
    { rootState, rootGetters, dispatch },
    data
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.PostProcessingPrimaryValue.create(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllPostProcessingPrimaryValues");
    return responseData;
  },
  async updatePostProcessingPrimaryValue(
    { rootState, rootGetters, dispatch },
    data
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.PostProcessingPrimaryValue.update(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllPostProcessingPrimaryValues");
    return responseData;
  },
  async deletePostProcessingPrimaryValue(
    { rootState, rootGetters, dispatch },
    data
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.PostProcessingPrimaryValue.delete(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllPostProcessingPrimaryValues");
    return responseData;
  },
  // POST PROCESSING VALUE: SECONDARY
  async getAllPostProcessingSecondaryValues({ commit }) {
    const responseData =
      await API_MODULES.PostProcessingSecondaryValue.getAll();
    commit(
      "SET_LIST_OF_POST_PROCESSING_SECONDARY_VALUES",
      _.defaultTo(responseData, [])
    );
  },
  async createPostProcessingSecondaryValue(
    { rootState, rootGetters, dispatch },
    data
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.PostProcessingSecondaryValue.create(
      requestData,
      permissionID
    );
    await dispatch(
      "refreshDataByAction",
      "getAllPostProcessingSecondaryValues"
    );
    return responseData;
  },
  async updatePostProcessingSecondaryValue(
    { rootState, rootGetters, dispatch },
    data
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.PostProcessingSecondaryValue.update(
      requestData,
      permissionID
    );
    await dispatch(
      "refreshDataByAction",
      "getAllPostProcessingSecondaryValues"
    );
    return responseData;
  },
  async deletePostProcessingSecondaryValue(
    { rootState, rootGetters, dispatch },
    data
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.PostProcessingSecondaryValue.delete(
      requestData,
      permissionID
    );
    await dispatch(
      "refreshDataByAction",
      "getAllPostProcessingSecondaryValues"
    );
    return responseData;
  },
  // POST PROCESSING VALUE: TERTIARY
  async getAllPostProcessingTertiaryValues({ commit }) {
    const responseData = await API_MODULES.PostProcessingTertiaryValue.getAll();
    commit(
      "SET_LIST_OF_POST_PROCESSING_TERTIARY_VALUES",
      _.defaultTo(responseData, [])
    );
  },
  async createPostProcessingTertiaryValue(
    { rootState, rootGetters, dispatch },
    data
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.PostProcessingTertiaryValue.create(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllPostProcessingTertiaryValues");
    return responseData;
  },
  async updatePostProcessingTertiaryValue(
    { rootState, rootGetters, dispatch },
    data
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.PostProcessingTertiaryValue.update(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllPostProcessingTertiaryValues");
    return responseData;
  },
  async deletePostProcessingTertiaryValue(
    { rootState, rootGetters, dispatch },
    data
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.PostProcessingTertiaryValue.delete(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllPostProcessingTertiaryValues");
    return responseData;
  },
  // POST PROCESSING VALUE: QUATERNARY
  async getAllPostProcessingQuaternaryValues({ commit }) {
    const responseData =
      await API_MODULES.PostProcessingQuaternaryValue.getAll();
    commit(
      "SET_LIST_OF_POST_PROCESSING_QUATERNARY_VALUES",
      _.defaultTo(responseData, [])
    );
  },
  async createPostProcessingQuaternaryValue(
    { rootState, rootGetters, dispatch },
    data
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.PostProcessingQuaternaryValue.create(
      requestData,
      permissionID
    );
    await dispatch(
      "refreshDataByAction",
      "getAllPostProcessingQuaternaryValues"
    );
    return responseData;
  },
  async updatePostProcessingQuaternaryValue(
    { rootState, rootGetters, dispatch },
    data
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.PostProcessingQuaternaryValue.update(
      requestData,
      permissionID
    );
    await dispatch(
      "refreshDataByAction",
      "getAllPostProcessingQuaternaryValues"
    );
    return responseData;
  },
  async deletePostProcessingQuaternaryValue(
    { rootState, rootGetters, dispatch },
    data
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.PostProcessingQuaternaryValue.delete(
      requestData,
      permissionID
    );
    await dispatch(
      "refreshDataByAction",
      "getAllPostProcessingQuaternaryValues"
    );
    return responseData;
  },
  segmentedData({ state }, list) {
    const updatedList = list.map((item) => {
      const [
        product1_SN,
        product2_SN,
        product3_SN,
        attributeType_SN,
        attributeValue_SN,
        postProcessingType_SN,
        postProcessingValueNotShown,
      ] = item.productId.split("|");

      // 將 postProcessingValueNotShown 轉換為數組
      const postProcessingValueNotShownArray = postProcessingValueNotShown
        ? postProcessingValueNotShown.split("、") // 使用分隔符將字符串拆分為數組
        : []; // 如果 postProcessingValueNotShown 為空，返回空數組

      const attributeType = _.find(state.listOfAttributeTypes, {
        sn: Number(attributeType_SN),
      });
      const attributeValue = _.find(state.listOfAttributeValues, {
        sn: Number(attributeValue_SN),
      });
      const postProcessingType = _.find(state.listOfPostProcessingTypes, {
        sn: Number(postProcessingType_SN),
      });

      return {
        ...item,
        product1_SN: Number(product1_SN),
        product2_SN: Number(product2_SN),
        product3_SN: Number(product3_SN),
        attributeType_SN: Number(attributeType_SN),
        attributeType_ID: attributeType?.attributeId,
        attributeValue_SN: Number(attributeValue_SN),
        attributeValue_name: attributeValue?.chName,
        attributeValueVender_ID: Number(attributeValue?.forVender),
        postProcessingType_SN: Number(postProcessingType_SN),
        postProcessingType_ID: postProcessingType?.postProcId,
        postProcessingValueNotShown: postProcessingValueNotShown,
        postProcessingValueNotShownArray: postProcessingValueNotShownArray,
      };
    });
    return updatedList;
  },
  // POST PROCESSING COMBINATION
  async getAllPostProcessingDetailsByPostProcessingInRawForm(
    { rootState },
    data
  ) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData =
      await API_MODULES.PostProcessingCombination.getAllByPostProcessingInRawForm(
        requestData
      );
    return responseData;
  },
  async getAllPostProcessingDetailsByPostProcessingInStructuredForm(
    { rootState },
    data
  ) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData =
      await API_MODULES.PostProcessingCombination.getAllByPostProcessingInStructuredForm(
        requestData
      );
    return responseData;
  },
  async readAllPostProcessingDetailsByProductAttribute({ rootState }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData =
      await API_MODULES.PostProcessingCombination.readByProductAttribute(
        requestData
      );

    return responseData;
  },
  // PRODUCT WITH ATTRIBUTE CONFIGURATION
  async getAllProductsWithAttributeConfigurations({ state, commit, dispatch }) {
    await dispatch("getAllPrintUnits");
    await dispatch("getAllAttributeTypes");
    await dispatch("getAllAttributeValues");
    await dispatch("getAllProducts");
    await dispatch("getAllProductsWithAttributeTypesConfigurations");
    await dispatch("getAllProductsWithAttributeValuesConfigurations");
    const responseData = await dispatch(
      "segmentedProductAttributeConfiguration",
      state.listOfProductsWithAttributeValuesConfigurations
    );
    commit(
      "SET_LIST_OF_PRODUCTS_WITH_CONFIGURATIONS",
      _.defaultTo(responseData, [])
    );
  },
  segmentedProductAttributeConfiguration({ state, getters }, list) {
    const {
      listOfAttributeTypes,
      listOfAttributeValues,
      listOfCategories,
      listOfClasses,
      listOfModels,
      listOfPrintingVendors,
      listOfPrintUnits,
    } = state;

    const { listOfProductAttributeTypesBySelectedProduct } = state.viewBus;

    // 輔助函數：查找產品屬性
    const findProductAttribute = (sn) =>
      listOfProductAttributeTypesBySelectedProduct.find(
        (item) => item.productAttributeDetails.productAttributeSn === Number(sn)
      )?.productAttributeDetails || {};

    // 輔助函數：查找屬性類型
    const findAttributeType = (attributeSn) =>
      _.find(listOfAttributeTypes, { sn: Number(attributeSn) }) || {};

    // 輔助函數：查找屬性值
    const findAttributeValue = (attributeValueSn) =>
      _.find(listOfAttributeValues, { sn: Number(attributeValueSn) }) || {};

    // 輔助函數：查找單位
    const findUnit = (unitSn) => _.find(listOfPrintUnits, { sn: unitSn }) || {};

    // 輔助函數：構建產品屬性配置
    const buildProductAttributeConfig = (productAttribute, attributeValue) => {
      const attributeType = findAttributeType(productAttribute.attributeSn);
      const unit = findUnit(attributeValue.unitSn);

      return {
        productAttributeSn: productAttribute.productAttributeSn,
        attributeType: {
          sn: productAttribute.attributeSn,
          id: attributeType.attributeId,
          name: attributeType.attributeName,
          annotation: attributeType.annotation,
        },
        attributeValue: {
          sn: attributeValue.sn,
          id: attributeValue.idToAttributeValues,
          chName: attributeValue.chName,
          enName: attributeValue.enName,
          quantity: attributeValue.quantity,
          unit: {
            sn: unit.sn,
            chUnitName: unit.chUnitName,
            enUnitName: unit.enUnitName,
          },
          annotation: attributeValue.annotation,
        },
      };
    };

    const sortedListOfProductsWithAttributeValuesConfigurations =
      getters.sortedProductAttributeConfigurationByAttributeValue(list);

    const updatedList =
      sortedListOfProductsWithAttributeValuesConfigurations.map((item) => {
        const {
          sn,
          productAttributeSn1,
          productAttributeSn2,
          productAttributeSn3,
          attributeValueSn1,
          attributeValueSn2,
          attributeValueSn3,
          vendors,
        } = item;

        const productAttribute1 = findProductAttribute(productAttributeSn1);
        const productAttribute2 = findProductAttribute(productAttributeSn2);
        const productAttribute3 = findProductAttribute(productAttributeSn3);

        const attributeValue1 = findAttributeValue(attributeValueSn1);
        const attributeValue2 = findAttributeValue(attributeValueSn2);
        const attributeValue3 = findAttributeValue(attributeValueSn3);

        const product3 =
          _.find(listOfModels, {
            product3_SN: productAttribute1.product3_SN,
          }) || {};
        const product1 =
          _.find(listOfCategories, { product1_SN: product3.product1_SN }) || {};
        const product2 =
          _.find(listOfClasses, { product2_SN: product3.product2_SN }) || {};

        const listOfVendors = JSON.parse(vendors).map((vendorSn) => {
          const vendor = _.find(listOfPrintingVendors, { vendorSn });
          return {
            vendorSn: vendor?.vendorSn,
            chName: vendor?.chName,
            enName: vendor?.enName,
            class: vendor?.class,
          };
        });

        return {
          sn: sn,
          product_Details: {
            category: product1,
            class: product2,
            model: product3,
          },
          productAttribute1_Configuration: buildProductAttributeConfig(
            productAttribute1,
            attributeValue1
          ),
          productAttribute2_Configuration: productAttributeSn2
            ? buildProductAttributeConfig(productAttribute2, attributeValue2)
            : {},
          productAttribute3_Configuration: productAttributeSn3
            ? buildProductAttributeConfig(productAttribute3, attributeValue3)
            : {},
          vendors: listOfVendors,
        };
      });

    return updatedList;
  },
  // PRODUCT WITH ATTRIBUTE TYPE CONFIGURATION
  async getAllProductsWithAttributeTypesConfigurations({ commit, getters }) {
    const responseData =
      await API_MODULES.ProductWithAttributeTypeConfiguration.getAll();
    const sortedResponseData =
      getters.sortedProductAttributeTypeConfiguration(responseData);
    commit(
      "SET_LIST_OF_PRODUCTS_WITH_ATTRIBUTE_TYPES_CONFIGURATIONS",
      _.defaultTo(sortedResponseData, [])
    );
  },
  async getAllProductsWithAttributeTypesConfigurationsByProduct(
    { rootState },
    data
  ) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData =
      await API_MODULES.ProductWithAttributeTypeConfiguration.getAllByProduct(
        requestData
      );
    return responseData;
  },
  async createProductWithAttributeTypeConfiguration(
    { rootState, rootGetters, dispatch },
    data
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData =
      await API_MODULES.ProductWithAttributeTypeConfiguration.create(
        requestData,
        permissionID
      );
    await dispatch(
      "refreshDataByAction",
      "getAllProductsWithAttributeTypesConfigurations"
    );
    return responseData;
  },
  async updateProductWithAttributeTypeConfiguration(
    { rootState, rootGetters, dispatch },
    data
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData =
      await API_MODULES.ProductWithAttributeTypeConfiguration.update(
        requestData,
        permissionID
      );
    await dispatch(
      "refreshDataByAction",
      "getAllProductsWithAttributeTypesConfigurations"
    );
    return responseData;
  },
  async deleteProductWithAttributeTypeConfiguration(
    { rootState, rootGetters, dispatch },
    data
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData =
      await API_MODULES.ProductWithAttributeTypeConfiguration.delete(
        requestData,
        permissionID
      );
    await dispatch(
      "refreshDataByAction",
      "getAllProductsWithAttributeTypesConfigurations"
    );
    return responseData;
  },
  // PRODUCT WITH ATTRIBUTE VALUE CONFIGURATION
  async getAllProductsWithAttributeValuesConfigurations({ commit }) {
    const responseData =
      await API_MODULES.ProductWithAttributeValueConfiguration.getAll();
    commit(
      "SET_LIST_OF_PRODUCTS_WITH_ATTRIBUTE_VALUES_CONFIGURATIONS",
      _.defaultTo(responseData, [])
    );
  },
  async createProductWithAttributeValueConfiguration(
    { rootState, rootGetters, dispatch },
    data
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData =
      await API_MODULES.ProductWithAttributeValueConfiguration.create(
        requestData,
        permissionID
      );
    await dispatch(
      "refreshDataByAction",
      "getAllProductsWithAttributeValuesConfigurations"
    );
    return responseData;
  },
  async createSetProductWithAttributeValueConfiguration(
    { rootState, rootGetters },
    data
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData =
      await API_MODULES.ProductWithAttributeValueConfiguration.create(
        requestData,
        permissionID
      );
    return responseData;
  },
  async createBatchProductWithAttributeValueConfiguration(
    { rootState, rootGetters, dispatch },
    dataList
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = dataList.map((data) => {
      return { ...data, token: rootState.auth.token };
    });
    const responseData =
      await API_MODULES.ProductWithAttributeValueConfiguration.createBatch(
        requestData,
        permissionID
      );
    await dispatch(
      "refreshDataByAction",
      "getAllProductsWithAttributeValuesConfigurations"
    );
    return responseData;
  },
  async updateProductWithAttributeValueConfiguration(
    { rootState, rootGetters, dispatch },
    data
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData =
      await API_MODULES.ProductWithAttributeValueConfiguration.update(
        requestData,
        permissionID
      );
    await dispatch(
      "refreshDataByAction",
      "getAllProductsWithAttributeValuesConfigurations"
    );
    return responseData;
  },
  async deleteProductWithAttributeValueConfiguration(
    { rootState, rootGetters, dispatch },
    data
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData =
      await API_MODULES.ProductWithAttributeValueConfiguration.delete(
        requestData,
        permissionID
      );
    await dispatch(
      "refreshDataByAction",
      "getAllProductsWithAttributeValuesConfigurations"
    );
    return responseData;
  },
  async deleteSetProductWithAttributeValueConfiguration(
    { rootState, rootGetters },
    data
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData =
      await API_MODULES.ProductWithAttributeValueConfiguration.delete(
        requestData,
        permissionID
      );
    return responseData;
  },
  async deleteBatchProductWithAttributeValueConfiguration(
    { rootState, rootGetters, dispatch },
    dataList
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = dataList.map((data) => {
      return { ...data, token: rootState.auth.token };
    });
    const responseData =
      await API_MODULES.ProductWithAttributeValueConfiguration.deleteBatch(
        requestData,
        permissionID
      );
    await dispatch(
      "refreshDataByAction",
      "getAllProductsWithAttributeValuesConfigurations"
    );
    return responseData;
  },
  async replaceBatchAdvancedProductWithAttributeValueConfiguration(
    { rootState, rootGetters, dispatch },
    dataList
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = dataList.map((data) => {
      return { ...data, token: rootState.auth.token };
    });
    const responseData =
      await API_MODULES.ProductWithAttributeValueConfiguration.replaceBatchAdvanced(
        requestData,
        permissionID
      );
    await dispatch(
      "refreshDataByAction",
      "getAllProductsWithAttributeValuesConfigurations"
    );
    return responseData;
  },
  async replaceBatchMultiProductWithAttributeValueConfiguration(
    { rootState, rootGetters, dispatch },
    dataList
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = dataList.map((data) => {
      return { ...data, token: rootState.auth.token };
    });
    const responseData =
      await API_MODULES.ProductWithAttributeValueConfiguration.replaceBatchMulti(
        requestData,
        permissionID
      );
    await dispatch(
      "refreshDataByAction",
      "getAllProductsWithAttributeValuesConfigurations"
    );
    return responseData;
  },
  // PRODUCT WITH ATTRIBUTE CONFIGURATION
  async getAllProductsWithAttributeConfigurationsByProductAttributes(
    { rootState, dispatch },
    data
  ) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData =
      await API_MODULES.ProductWithAttributeConfiguration.getAllByProductAttributes(
        requestData
      );
    const segmentedData = await dispatch(
      "segmentedProductAttributeConfiguration",
      responseData
    );
    return segmentedData;
  },
  async getAllAttribute1OfProductsWithAttributeConfigurationsByProductAttribute1(
    { rootState, dispatch },
    data
  ) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData =
      await API_MODULES.ProductWithAttributeConfiguration.getAllOfAttribute1ByProductAttribute1(
        requestData
      );
    const segmentedData = await dispatch(
      "segmentedProductAttributeConfiguration",
      responseData
    );
    return segmentedData;
  },
  // PRODUCT WITH POST PROCESSING CONFIGURATION
  async getAllProductsWithPostProcessingConfigurationsByProduct(
    { rootState },
    data
  ) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData =
      await API_MODULES.ProductWithPostProcessingConfiguration.getAllByProduct(
        requestData
      );
    return responseData;
  },
  async createProductWithPostProcessingConfiguration(
    { rootState, rootGetters },
    data
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData =
      await API_MODULES.ProductWithPostProcessingConfiguration.create(
        requestData,
        permissionID
      );
    return responseData;
  },
  async updateProductWithPostProcessingConfiguration(
    { rootState, rootGetters },
    data
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData =
      await API_MODULES.ProductWithPostProcessingConfiguration.update(
        requestData,
        permissionID
      );
    return responseData;
  },
  async updateOrderByNumOfProductWithPostProcessingConfiguration(
    { rootState, rootGetters },
    data
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData =
      await API_MODULES.ProductWithPostProcessingConfiguration.updateOrderByNum(
        requestData,
        permissionID
      );
    return responseData;
  },
  async deleteProductWithPostProcessingConfigurationByPostProcessingType(
    { rootState, rootGetters },
    data
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData =
      await API_MODULES.ProductWithPostProcessingConfiguration.deleteByPostProcessingType(
        requestData,
        permissionID
      );
    return responseData;
  },
  async deleteProductWithPostProcessingConfigurationByReferAttributeType(
    { rootState, rootGetters },
    data
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData =
      await API_MODULES.ProductWithPostProcessingConfiguration.deleteByReferAttributeType(
        requestData,
        permissionID
      );
    return responseData;
  },
  async deleteProductWithPostProcessingConfigurationByReferAttributeTypeAndValue(
    { rootState, rootGetters },
    data
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData =
      await API_MODULES.ProductWithPostProcessingConfiguration.deleteByReferAttributeTypeAndValue(
        requestData,
        permissionID
      );
    return responseData;
  },
  // PRINTING VENDOR
  async getAllPrintingVendors({ commit }) {
    const responseData = await API_MODULES.PrintingVendor.getAll();
    commit("SET_LIST_OF_PRINTING_VENDORS", _.defaultTo(responseData, []));
  },
  async createPrintingVendor({ rootState, rootGetters, dispatch }, data) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.PrintingVendor.create(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllPrintingVendors");
    return responseData;
  },
  async updatePrintingVendor({ rootState, rootGetters, dispatch }, data) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.PrintingVendor.update(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllPrintingVendors");
    return responseData;
  },
  async deletePrintingVendor({ rootState, rootGetters, dispatch }, data) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.PrintingVendor.delete(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllPrintingVendors");
    return responseData;
  },
  // PRINT UNIT
  async getAllPrintUnits({ commit }) {
    const responseData = await API_MODULES.PrintUnit.getAll();
    commit("SET_LIST_OF_PRINT_UNITS", _.defaultTo(responseData, []));
  },
  async createPrintUnit({ rootState, rootGetters, dispatch }, data) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.PrintUnit.create(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllPrintUnits");
    return responseData;
  },
  async updatePrintUnit({ rootState, rootGetters, dispatch }, data) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.PrintUnit.update(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllPrintUnits");
    return responseData;
  },
  async deletePrintUnit({ rootState, rootGetters, dispatch }, data) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.PrintUnit.delete(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllPrintUnits");
    return responseData;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
