import {
  replaceUrl,
  postCRUDRequest,
  getCRUDRequest,
} from "@/api/apiServices/commonService";
import { API_ROUTES } from "@/api/apiConfig";
import REQUEST_MODELS from "@/api/requestModels/OrderManagement/order";

const Customer = {
  async search(requestData) {
    const reqModel = new REQUEST_MODELS.CUSTOMER_SEARCH(
      requestData.token || "",
      requestData.customerId || "",
      requestData.customerType || 0,
      requestData.phone || "",
      requestData.vATId || "",
      requestData.chName || "",
      requestData.enName || "",
      requestData.skipRows || 0,
      requestData.nextRows || 0
    );
    let url = API_ROUTES.CUSTOMER.CUSTOMER.SEARCH;

    return await postCRUDRequest(url, reqModel);
  },
  async create(requestData) {
    let listOfContacts = handleContactsList(requestData);

    const reqModel = new REQUEST_MODELS.CUSTOMER_CREATE(
      requestData.token || "",
      requestData.departmentSn || 0,
      requestData.customerType || 0,
      requestData.firstShopSN || 0,
      requestData.chName || "",
      requestData.enName || "",
      requestData.county || "",
      requestData.district || "",
      requestData.detailedAddress || "",
      requestData.vATId || "",
      requestData.titleOnInvoice || "",
      requestData.industryType || "",
      requestData.careerType || "",
      listOfContacts || [],
      requestData.accountSettings || []
    );
    let url = API_ROUTES.CUSTOMER.CUSTOMER.CREATE;

    return await postCRUDRequest(url, reqModel);
  },
  async read(requestData) {
    const reqModel = new REQUEST_MODELS.CUSTOMER_READ(
      requestData.token || "",
      requestData.customerSn || 0
    );
    let url = API_ROUTES.CUSTOMER.CUSTOMER.READ;

    return await postCRUDRequest(url, reqModel);
  },
};
const Contact = {
  async create(requestData) {
    let listOfContacts = handleContactsList(requestData);
    const reqModel = new REQUEST_MODELS.CONTACT_CREATE(
      requestData.token || "",
      requestData.departmentSn || 0,
      requestData.customerSn || 0,
      listOfContacts || []
    );
    let url = API_ROUTES.CUSTOMER.CONTACT.CREATE;

    return await postCRUDRequest(url, reqModel);
  },
};
const VATId = {
  async search(vATId) {
    let url = API_ROUTES.VAT.VATId.READ;
    url = replaceUrl(url, "VAT_Id", vATId);

    return await getCRUDRequest(url);
  },
};
const Industry = {
  async getAll() {
    let url = API_ROUTES.VAT.Industry.READ;
    return await getCRUDRequest(url);
  },
};
const Career = {
  async getAll() {
    let url = API_ROUTES.VAT.Career.READ;
    return await getCRUDRequest(url);
  },
};

const ProductCategory = {
  async read(requestData) {
    const reqModel = new REQUEST_MODELS.PRODUCT_CATEGORY_READ(
      requestData.token || "",
      requestData.sn || 0,
      requestData.sellStatus || 0
    );
    let url = API_ROUTES.PRODUCT.CATEGORY.READ_BY_TYPE;

    return await postCRUDRequest(url, reqModel);
  },
};
const ProductClass = {
  async read(requestData) {
    const reqModel = new REQUEST_MODELS.PRODUCT_CLASS_READ(
      requestData.token || "",
      requestData.sn || 0,
      requestData.sellStatus || 0
    );
    let url = API_ROUTES.PRODUCT.CLASS.READ_BY_CATEGORY;

    return await postCRUDRequest(url, reqModel);
  },
};
const ProductType = {
  async read(requestData) {
    const reqModel = new REQUEST_MODELS.PRODUCT_TYPE_READ(
      requestData.token || "",
      requestData.sn || 0,
      requestData.sellStatus || 0
    );
    let url = API_ROUTES.PRODUCT.MODEL.READ_BY_CLASS;

    return await postCRUDRequest(url, reqModel);
  },
};
const ProductWithAttributeTypeAndValue = {
  async read(requestData) {
    const reqModel =
      new REQUEST_MODELS.PRODUCT_WITH_ATTRIBUTE_TYPE_AND_VALUE_READ(
        requestData.token || "",
        requestData.product3_SN || 0
      );
    let url =
      API_ROUTES.PRODUCT.PRODUCT_WITH_ATTRIBUTE_CONFIGURATION
        .READ_BY_PRODUCT_SN;

    return await postCRUDRequest(url, reqModel);
  },
};

/**
 * 處理聯絡人資料，回傳依照格式整理過的陣列清單
 * @returns {array}
 */
const handleContactsList = (requestData) => {
  // 處理聯絡人資料
  let listOfContacts;

  if (!Array.isArray(requestData.contacts)) {
    listOfContacts = [];
  } else {
    listOfContacts = requestData.contacts.map((data) =>
      createSingleContactsModel(data)
    );
  }
  return listOfContacts;
};
/**
 * 檢查聯絡人資料，返回後再依據需求組成陣列資料使用
 * @param {object} requestData 要處理的聯絡人資料
 * @returns {object} 返回單一位聯絡人資料
 */
const createSingleContactsModel = (requestData) => {
  return new REQUEST_MODELS.CONTACT_MODEL(
    requestData.token || "",
    requestData.sn || 0,
    requestData.customerSn || 0,
    requestData.brandSn || 0,
    requestData.chName || "",
    requestData.enName || "",
    requestData.county || "",
    requestData.district || "",
    requestData.detailedAddress || "",
    requestData.citizenId || "",
    requestData.residentCertificateId || "",
    requestData.passportId || "",
    requestData.jobTitle || "",
    requestData.cellphone || "",
    requestData.phone1 || "",
    requestData.phoneExtension1 || "",
    requestData.phone2 || "",
    requestData.phoneExtension2 || "",
    requestData.email || "",
    requestData.deliverMethod || 0,
    requestData.priority || 0,
    requestData.deliverEnv || 0,
    requestData.deliverRule || 0
  );
};

export default {
  Customer,
  VATId,
  Industry,
  Career,
  Contact,
  ProductCategory,
  ProductClass,
  ProductType,
  ProductWithAttributeTypeAndValue,
};
